import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  SET_APP_ERROR,
  UPDATE_LATEST_NEWS_DATE,
  SET_BROWSER_COUNTRY,
  UPLOAD_FILE,
  CREATE_BUG_REPORT,
  CREATE_ABROAD_BUG_REPORT,
  FETCH_COUNTRIES,
  DEFINE_BROWSER_COUNTRY,
} from './types';

export const setAppError = createAction(SET_APP_ERROR);
export const updateLatestNewsDate = createAction(UPDATE_LATEST_NEWS_DATE);
export const setBrowserCountry = createAction(SET_BROWSER_COUNTRY);

export const uploadFile = createAlertAction(UPLOAD_FILE);
export const createBugReport = createAlertAction(CREATE_BUG_REPORT);
export const createAbroadBugReport = createAlertAction(CREATE_ABROAD_BUG_REPORT);
export const fetchCountries = createAlertAction(FETCH_COUNTRIES);
export const defineBrowserCountry = createAlertAction(DEFINE_BROWSER_COUNTRY);
