import { takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';

import api, { updateToken } from 'api';
import { withAlert, applyCancelToken } from 'store/alerts';
import { getTeam } from 'store/organizations/selectors';
import { updateTeam } from 'store/organizations/actions';
import { VOUCHER_TYPE } from 'store/patients/consts';
import { setLanguage } from '../utils';

import { ID, URL, TOKEN, CODE, LANGUAGE } from '.';
import {
  FETCH_LICENSE_BY_VOUCHER,
  FETCH_STATISTICS,
  RESTORE,
  LOGIN,
  REGISTER_USER,
  REGISTER_ABROAD_USER,
  SAVE_USER,
  DELETE_USER,
  UPDATE_PASSWORD,
  RESET_PASSWORD,
  SAVE_PASSWORD,
  CREATE_2FA,
  VALIDATE_2FA,
  DELETE_2FA,
  AUTH_2FA,
} from './types';
import { updateUser } from './actions';
import { getUserProp } from './selectors';

function* restore(action) {
  const data = yield call(api.get, '/session', { baseURL: URL, ...applyCancelToken(action) });
  setLanguage(data[LANGUAGE]);
  yield put(updateUser(data));
}

function* login({ payload }) {
  const data = yield call(api.post, '/login', payload, { baseURL: URL });
  updateToken(data[TOKEN]);
  setLanguage(data[LANGUAGE]);
  yield put(updateUser(data));
}

function* registerUser({ payload }) {
  const data = yield call(api.post, '/users', payload);
  const team = yield select(getTeam);
  yield put(updateTeam([...team, data]));
}

function* registerAbroadUser({ payload }) {
  yield call(api.post, '/subscriptions/mobile', payload);
}

function* saveUser({ payload, ...rest }) {
  const id = yield select(getUserProp(ID));
  const data = yield call(api.patch, `/users/${id}`, payload, applyCancelToken(rest));
  setLanguage(data[LANGUAGE]);
  yield put(updateUser(data));

  return { success: 'User Update Succeeded' };
}

function* deleteUser({ payload }) {
  const data = yield call(api.delete, `/users/${payload}`);
  const team = yield select(getTeam);
  yield put(updateTeam(team.filter((member) => member[ID] !== data[ID])));
}

function* updatePassword({ payload }) {
  yield call(api.post, '/updatepassword', payload, { baseURL: URL });

  return { success: "User's password was updated" };
}

function* resetPassword({ payload, ...rest }) {
  return yield call(api.post, '/reset', payload, { baseURL: URL, ...applyCancelToken(rest) });
}

function* savePassword({ payload }) {
  return yield call(api.post, '/setpassword', payload, { baseURL: URL });
}

function* fetchLicenseByVoucher({ payload, ...rest }) {
  const data = yield call(api.get, '/licenses', { params: { action: VOUCHER_TYPE, [CODE]: payload }, ...applyCancelToken(rest) });

  if (data?.error) return { error: data.error };

  return { success: data };
}

function* fetchStatistics(action) {
  return { success: yield call(api.get, '/licenses/statistics', applyCancelToken(action)) };
}

function* create2fa(action) {
  return { success: yield call(api.post, '/auth/2fa/create', {}, { baseURL: URL, ...applyCancelToken(action) }) };
}

function* validate2fa({ payload, ...rest }) {
  yield call(api.post, '/auth/2fa/validate', { [CODE]: payload }, { baseURL: URL, ...applyCancelToken(rest) });
}

function* delete2fa() {
  const data = yield call(api.post, '/auth/2fa/delete', {}, { baseURL: URL });
  updateToken(data[TOKEN]);
  yield put(updateUser(data));
}

function* auth2fa({ payload, ...rest }) {
  const data = yield call(api.post, '/auth/2fa/authenticate', { [CODE]: payload }, { baseURL: URL, ...applyCancelToken(rest) });
  updateToken(null, data[TOKEN]);
  setLanguage(data[LANGUAGE]);
  yield put(updateUser(data));
}

export default function* watchUser() {
  yield takeLatest(RESTORE, withAlert(restore));
  yield takeEvery(LOGIN, withAlert(login));
  yield takeEvery(REGISTER_USER, withAlert(registerUser));
  yield takeEvery(REGISTER_ABROAD_USER, withAlert(registerAbroadUser));
  yield takeLatest(SAVE_USER, withAlert(saveUser));
  yield takeEvery(DELETE_USER, withAlert(deleteUser));
  yield takeEvery(UPDATE_PASSWORD, withAlert(updatePassword));
  yield takeLatest(RESET_PASSWORD, withAlert(resetPassword));
  yield takeEvery(SAVE_PASSWORD, withAlert(savePassword));
  yield takeLatest(CREATE_2FA, withAlert(create2fa));
  yield takeLatest(VALIDATE_2FA, withAlert(validate2fa));
  yield takeEvery(DELETE_2FA, withAlert(delete2fa));
  yield takeLatest(AUTH_2FA, withAlert(auth2fa));
  yield takeLatest(FETCH_LICENSE_BY_VOUCHER, withAlert(fetchLicenseByVoucher));
  yield takeLatest(FETCH_STATISTICS, withAlert(fetchStatistics));
}
