import { path, pathEq, pipe, pathOr, equals } from 'ramda';

import { getBrowserCountry } from 'store/app/selectors';

import { FEATURE_NAME as $, ORGANIZATION, TEAM, CARD, KIND, COUNTRY } from './consts';

export const getOrganization = path([$, ORGANIZATION]);
export const getOrganizationProp = (pr) => path([$, ORGANIZATION, pr]);
export const getTeam = path([$, TEAM]);
export const getCard = path([$, ORGANIZATION, CARD]);
export const getCardProp = (pr) => path([$, ORGANIZATION, CARD, pr]);
export const getIsAlleye = pathEq(0, [$, ORGANIZATION, KIND]);
export const getIsAlleyeOne = pathEq(1, [$, ORGANIZATION, KIND]);
export const getIsFromUS = (state) => pipe(pathOr(getBrowserCountry(state), [$, ORGANIZATION, COUNTRY]), equals('US'))(state);
