import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFullName, useUserActions } from 'store/user';
import AppError from '../AppError';

import { Container, Wrapper, Link } from './styles';

const Desktop = () => {
  const { t } = useTranslation('app');
  const userName = useFullName();
  const { logout } = useUserActions();

  return (
    <Container>
      <AppError />
      <Wrapper>
        <Link to="/dashboard">{t('Dashboard')}</Link>
        <Link to="/patients">{t('Patients')}</Link>
        <Link to="/account">{t('Admin')}</Link>
      </Wrapper>
      <Wrapper>
        <Link to="/profile" $fontStyle="font14">
          {userName}
        </Link>
        <Link to="" onClick={logout} $fontStyle="font14">
          {t('LogOut')}
        </Link>
      </Wrapper>
    </Container>
  );
};

export default Desktop;
