import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppError, useAppActions } from 'store/app';
import { useUser, ID } from 'store/user';

import { Container, Text, SupportLink } from './styles';

const AppError = () => {
  const timer = useRef();
  const { t } = useTranslation('app');
  const error = useAppError();
  const userId = useUser(ID);
  const { setAppError } = useAppActions();

  useEffect(() => {
    if (error) {
      timer.current = setTimeout(() => {
        setAppError();
        timer.current = null;
      }, 7000);
    }
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [error, setAppError]);

  return (
    <Container $isOpen={Boolean(error)}>
      {error && (
        <Text>
          {error} {userId && <SupportLink to="/bugreport">{t('Report a problem')}</SupportLink>}
        </Text>
      )}
    </Container>
  );
};

export default AppError;
