import React from 'react';
import { useMatch, Outlet } from 'react-router-dom';

import Header from '../ExternalHeader';

import { IMAGE_DATA } from './consts';
import { Container, Content, ImageWrap, Image, Footer } from './styles';

const ImageLayout = () => {
  const { webpSrc, jpgSrc, sizes, preloadImg, defaultImage, alt } = IMAGE_DATA[useMatch('/become-provider') ? 'slit' : 'app'];

  return (
    <>
      <Header />
      <Container>
        <Content>
          <Outlet />
        </Content>
        <ImageWrap>
          <Image webpSrc={webpSrc} jpgSrc={jpgSrc} sizes={sizes} preloadImg={preloadImg} src={defaultImage} alt={alt} />
        </ImageWrap>
      </Container>
      <Footer />
    </>
  );
};

export default ImageLayout;
