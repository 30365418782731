import { useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { keys, pathOr, pipe, filter, join, hasPath, path, replace, split } from 'ramda';

import { usePatient, FIRST_NAME, LAST_NAME } from 'store/patients';
import { routesMapping } from '../utils';

const DEFAULT_TITLE = 'Screening and monitoring for retinal diseases';
const DEFAULT_DESCRIPTION =
  'Alleye aims to prevent blindness so that people enjoy longer and healthier lives. The Alleye app is specialized in early detection and monitoring of retinal diseases, including age-related macular degeneration (AMD) and diabetic macular edema (DME).';

const generateNewsMeta = (data) =>
  keys(data).map((key) => ({
    title: pathOr(DEFAULT_TITLE, [key, 'title'], data),
    description: pathOr(DEFAULT_DESCRIPTION, [key, 'subtitle'], data),
    ...(hasPath([key, 'date'], data) && {
      date: pipe(path([key, 'date']), replace(/\.\d+Z$/, ''), split('T'), join(' '))(data),
    }),
  }));

export const usePageData = () => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation('news');

  const patientPage = useMatch('/patients/edit/:patientId');
  const [firstName, lastName] = usePatient([FIRST_NAME, LAST_NAME]);

  const newsItemPage = useMatch('/news/:newsId');
  const newsItemPagesMeta = useMemo(
    () => generateNewsMeta(i18n.getFixedT('en', 'news')('$content', { returnObjects: true })),
    [i18n]
  );

  const { title, description, withoutIndexing, date } = useMemo(
    () =>
      (patientPage && {
        title: pipe(filter(Boolean), join(' '))([firstName, lastName]) || '...',
        withoutIndexing: true,
      }) ||
      (newsItemPage && newsItemPage.params?.newsId && newsItemPagesMeta[newsItemPage.params.newsId]) ||
      routesMapping(pathname)?.meta ||
      {},
    [firstName, lastName, newsItemPage, newsItemPagesMeta, pathname, patientPage]
  );

  return {
    title: title || DEFAULT_TITLE,
    description: description || DEFAULT_DESCRIPTION,
    url: `${process.env.REACT_APP_HOSTNAME}${pathname}`,
    withoutIndexing: Boolean(withoutIndexing) || process.env.REACT_APP_TYPE !== 'production',
    date,
  };
};

export const filterMeta = [
  'meta[name=description]',
  "meta[property='og:title']",
  "meta[property='og:description']",
  "meta[property='og:url']",
  "meta[property='og:type']",
  "meta[name='twitter:title']",
  "meta[name='twitter:description']",
  'meta[name=robots]',
];
