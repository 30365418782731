import * as R from 'ramda';

import i18n from 'locale';
import worldPhones from './worldPhones.json';

const hiddenCardInfo = (numGroups) => {
  const bullet = String.fromCharCode(9679);
  const group4 = R.join('', R.repeat(bullet, 4));
  const hiddenDigits = R.join(' ', R.repeat(group4, numGroups));

  return hiddenDigits;
};

export const getCardInfo = (cardBrand, last4) =>
  cardBrand && last4 ? `${cardBrand.toUpperCase()}${String.fromCharCode(9)}${hiddenCardInfo(3)} ${last4}` : '';

export const getCalendarDays = (month, year) => {
  const days = [];
  const februaryDays = typeof year === 'number' && year % 4 ? 28 : 29;
  const monthDays = [0, 31, februaryDays, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const lastDay = monthDays[month];

  for (let i = 1; i <= lastDay; i += 1) {
    days.push({ id: i.toString(), label: i.toString() });
  }

  return days;
};

export const getCalendarMonths = () => [
  { id: '1', label: i18n.t('forms:January') },
  { id: '2', label: i18n.t('forms:February') },
  { id: '3', label: i18n.t('forms:March') },
  { id: '4', label: i18n.t('forms:April') },
  { id: '5', label: i18n.t('forms:May') },
  { id: '6', label: i18n.t('forms:June') },
  { id: '7', label: i18n.t('forms:July') },
  { id: '8', label: i18n.t('forms:August') },
  { id: '9', label: i18n.t('forms:September') },
  { id: '10', label: i18n.t('forms:October') },
  { id: '11', label: i18n.t('forms:November') },
  { id: '12', label: i18n.t('forms:December') },
];

export const getCalendarYears = (isFuture) => {
  const currentYear = new Date().getFullYear();
  const start = isFuture ? currentYear + 10 : currentYear;

  return R.times(
    (i) => {
      const id = (start - i).toString();

      return { id, label: id };
    },
    isFuture ? 11 : 120
  );
};

const getTimeObject = (i) => {
  const id = String(i);

  return { id, label: id.length === 1 ? `0${id}` : id };
};
export const getTimeHours = () => R.times(getTimeObject, 24);
export const getTimeMinutes = () => R.times(getTimeObject, 60);

export const extractErrorsFromYup = (validationError) =>
  R.pipe(
    R.propOr([], 'inner'),
    R.reduce((acc, { path, message }) => R.assocPath(path.split('.'), message, acc), {})
  )(validationError || {});

const getLocale = () => i18n.language;
const getDateFormatter = () =>
  new Intl.DateTimeFormat(getLocale(), {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

const getTimeFormatter = () =>
  new Intl.DateTimeFormat(getLocale(), {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
const getShortDateFormatter = () =>
  new Intl.DateTimeFormat(getLocale(), {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
export const parseDate = (timestamp) => {
  if (!timestamp) return '';

  return getDateFormatter().format(new Date(timestamp));
};
export const parseShortDate = (timestamp) => {
  if (!timestamp) return '';

  return getShortDateFormatter().format(new Date(timestamp));
};
export const parseDateWithTime = (timestamp, divider = ', ') => {
  if (!timestamp) return '';

  const date = new Date(timestamp);

  return `${getDateFormatter().format(date)}${divider}${getTimeFormatter().format(date)}`;
};

export const getPrettifyId = (id) => {
  if (!id || id.length !== 12) return id;

  return `${id.substring(0, 4)} ${id.substring(4, 8)} ${id.substring(8, 12)}`;
};

export const getCountries = () =>
  R.pipe(
    R.keys,
    R.map((key) => ({ id: key, label: worldPhones[key][0] }))
  )(worldPhones);

const RELEASE_DATE = new Date(2023, 5);
export const checkRelease = () => {
  const now = new Date();

  return now.valueOf() >= RELEASE_DATE.valueOf();
};
