import { useState, useEffect, useCallback, useRef } from 'react';

export const useWindowSize = () => {
  const [size, setSize] = useState({
    width: window?.innerWidth || 0,
    height: window?.innerHeight || 0,
  });
  const prevResult = useRef(size);

  const handleResize = useCallback(() => {
    const width = window?.innerWidth || 0;
    const height = window?.innerHeight || 0;
    const prev = prevResult.current;

    if (prev.width === width && prev.height === height) return;

    prevResult.current = { width, height };
    setSize({ width, height });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return size;
};
