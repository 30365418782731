import styled from 'styled-components';

import DynamicImage from 'components/DynamicImage';
import FooterDef from '../Footer';

export const Container = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: minmax(500px, 2fr) 3fr;
  grid-template-rows: 1fr auto;
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

export const Content = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  row-gap: 30px;
  align-items: center;
  padding: var(--header-height) 0 25px;
  @media (max-width: 991px) {
    grid-template-rows: auto;
    align-items: start;
  }
`;

export const ImageWrap = styled.div`
  min-height: calc(100 * var(--vh, 1vh));
  width: 100%;
  position: relative;
  overflow: hidden;
  @media (max-width: 991px) {
    min-height: auto;
  }
`;

export const Image = styled(DynamicImage)`
  position: absolute;
  inset: 0;
  @media (max-width: 991px) {
    display: block;
    position: static;
    height: auto;
  }
  @media (max-width: 991px) and (orientation: portrait) {
    aspect-ratio: 1/1;
  }
  @media (max-width: 991px) and (orientation: landscape) {
    aspect-ratio: 2/1;
  }
`;

export const Footer = styled(FooterDef)`
  grid-column: 1/-1;
`;
