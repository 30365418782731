import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_ORGANIZATION = getName('UPDATE_ORGANIZATION');
export const RESET_ORGANIZATION = getName('RESET_ORGANIZATION');
export const UPDATE_TEAM = getName('UPDATE_TEAM');
export const UPDATE_CARD_DATA = getName('UPDATE_CARD_DATA');

export const FETCH_ORGANIZATION = getName('FETCH_ORGANIZATION');
export const CREATE_ORGANIZATION = getName('CREATE_ORGANIZATION');
export const ACTIVATE_ORGANIZATION = getName('ACTIVATE_ORGANIZATION');
export const SAVE_ORGANIZATION = getName('SAVE_ORGANIZATION');
export const FETCH_ORGANIZATION_DASHBOARD = getName('FETCH_ORGANIZATION_DASHBOARD');
export const FETCH_TEAM = getName('FETCH_TEAM');
export const CREATE_CARD_SECRET = getName('CREATE_CARD_SECRET');
export const FETCH_PAYMENT_HISTORY = getName('FETCH_PAYMENT_HISTORY');
export const FETCH_PRODUCTS = getName('FETCH_PRODUCTS');
export const CREATE_PURCHASE = getName('CREATE_PURCHASE');
