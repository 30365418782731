import { pipe, prop, cond, equals, T, propOr, filter, join, when, not, props, path, map, ifElse, is, applySpec } from 'ramda';

import { ID, FIELDS, META, EXPAND, CREATED, SUB_USER, DATE } from 'api/consts';
import { parseDate } from 'utils/helper';

import {
  FILTER,
  ALLEYE,
  LICENSE,
  UNCONNECTED,
  FIRST_NAME,
  LAST_NAME,
  BIRTH,
  OD,
  OS,
  LAST_TEST,
  DURATION,
  VALUE,
  PREV_VALUE,
  COLOR,
  DRUG_LIST,
  OTHERS,
} from './consts';

const getName = (data, field) =>
  pipe(
    when(() => field, propOr({}, field)),
    props([FIRST_NAME, LAST_NAME]),
    filter(Boolean),
    join(' '),
    when(not, () => '')
  )(data);
const getStatusColor = cond([
  [equals('green'), () => 'var(--color-green-active)'],
  [equals('yellow'), () => 'var(--color-yellow)'],
  [equals('red'), () => 'var(--color-red-active)'],
  [T, () => 'var(--color-status-default)'],
]);
const getTestData = (data, field) => {
  const [od, os, lastTest] = pipe(
    when(() => field, propOr({}, field)),
    props([OD, OS, LAST_TEST])
  )(data);
  const results = map(
    ifElse(
      is(Object),
      applySpec({
        value: pipe(props([VALUE, PREV_VALUE]), ([val, prev]) => {
          const [hasVal, hasPrev] = [is(Number, val), is(Number, prev)];

          if (!(hasVal || hasPrev)) return '';
          if (hasVal && !hasPrev) return val;

          const diff = val - prev;

          return `${val} (${diff > 0 ? '+' : ''}${diff})`;
        }),
        color: pipe(prop(COLOR), getStatusColor),
      }),
      () => ''
    ),
    [od, os]
  );

  return [...results, parseDate(lastTest)];
};

export const getAlarmOptions = (type = '') => {
  const mapping = {
    [ALLEYE]: {
      url: '/alarms',
      params: {
        [EXPAND]: [SUB_USER],
        [FIELDS]: [
          `${SUB_USER}.${FIRST_NAME}`,
          `${SUB_USER}.${LAST_NAME}`,
          `${SUB_USER}.${OD}`,
          `${SUB_USER}.${OS}`,
          `${SUB_USER}.${LAST_TEST}`,
          DATE,
        ],
      },
      extractData: map((data = {}) => ({
        [ID]: path([SUB_USER, ID], data),
        items: [getName(data, SUB_USER), ...getTestData(data, SUB_USER), parseDate(data[DATE])],
      })),
    },
    [LICENSE]: {
      url: '/licenses/issues',
      params: {
        [EXPAND]: [SUB_USER],
        [FIELDS]: [`${SUB_USER}.${FIRST_NAME}`, `${SUB_USER}.${LAST_NAME}`, `${SUB_USER}.${BIRTH}`, DURATION],
      },
      extractData: map((data = {}) => ({
        [ID]: path([SUB_USER, ID], data),
        items: [getName(data, SUB_USER), parseDate(path([SUB_USER, BIRTH], data)), parseDate(data[DURATION])],
      })),
    },
    [UNCONNECTED]: {
      url: '/patients',
      params: {
        [FIELDS]: [FIRST_NAME, LAST_NAME, `${META}.${CREATED}`],
        [FILTER]: UNCONNECTED,
      },
      extractData: map((data = {}) => ({
        [ID]: prop(ID, data),
        items: [getName(data), parseDate(data[BIRTH]), parseDate(path([META, CREATED], data))],
      })),
    },
    default: {
      url: '/patients',
      params: {
        [FIELDS]: [FIRST_NAME, LAST_NAME, OD, OS, LAST_TEST, `${META}.${CREATED}`],
      },
      extractData: map((data = {}) => ({
        [ID]: prop(ID, data),
        items: [getName(data), ...getTestData(data), parseDate(path([META, CREATED], data))],
      })),
    },
  };

  return mapping[type] || mapping.default;
};

export const getDrugName = (drug) => (typeof drug === 'string' && DRUG_LIST[drug]?.name) || DRUG_LIST[OTHERS].name;
export const getDrugColor = (drug) => (typeof drug === 'string' && DRUG_LIST[drug]?.color) || DRUG_LIST[OTHERS].color;
