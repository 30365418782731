import styled from 'styled-components';

import { ReactComponent as EyeIcon } from 'assets/svg-icons/eyeFooter.svg';

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  background-color: var(--color-grey-blue);
  padding: 20px 30px;
  margin-top: 90px;
  @media (max-width: 991px) {
    gap: 25px;
    padding: 20px 15px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  max-width: 1600px;
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    justify-content: start;
    gap: 25px;
  }
`;

export const Title = styled.h1`
  grid-column: 1/-1;
  max-width: 1600px;
  font: var(--font79);
  color: var(--color-black);
  text-transform: uppercase;
  @media (max-width: 991px) {
    font: var(--font26);
  }
`;

export const Icon = styled(EyeIcon)`
  width: 114px;
  height: 61px;
  @media (max-width: 991px) {
    width: 42px;
    height: 22px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: start;
  gap: 10px;
  width: fit-content;
  &:nth-child(n + 4) {
    justify-self: end;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

export const ColumnTitle = styled.h2`
  height: 25px;
  width: max-content;
  border-radius: 13px;
  background-color: var(--color-white);
  font: var(--font20);
  color: var(--color-black);
  padding: 0 10px;
`;
