import { handleActions } from 'redux-actions';

import { APP_ERROR, LATEST_NEWS_DATE, BROWSER_COUNTRY } from './consts';
import { SET_APP_ERROR, UPDATE_LATEST_NEWS_DATE, SET_BROWSER_COUNTRY } from './types';
import { updateLatestNewsDate, getLatestNewsDate } from './utils';

export default handleActions(
  {
    [SET_APP_ERROR]: (state, { payload }) => ({
      ...state,
      [APP_ERROR]: payload || '',
    }),
    [UPDATE_LATEST_NEWS_DATE]: (state) => ({
      ...state,
      [LATEST_NEWS_DATE]: updateLatestNewsDate(),
    }),
    [SET_BROWSER_COUNTRY]: (state, { payload }) => ({
      ...state,
      [BROWSER_COUNTRY]: payload,
    }),
  },
  {
    [APP_ERROR]: '',
    [LATEST_NEWS_DATE]: getLatestNewsDate(),
    [BROWSER_COUNTRY]: null,
  }
);
