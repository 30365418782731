import styled from 'styled-components';

import FooterDef from '../Footer';

export const Main = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 40px 30px;
  @media (max-width: 1023px) {
    margin: 0;
    padding: 30px 20px 50px;
  }
`;

export const Footer = styled(FooterDef)`
  align-self: center;
  width: calc(100% - 60px);
  border-radius: 34px;
  & > div {
    max-width: none;
  }
  @media (max-width: 1023px) {
    width: 100%;
    border-radius: 0;
  }
`;

export const MarginBox = styled.div`
  min-height: 30px;
  @media (max-width: 1023px) {
    display: none;
  }
`;
