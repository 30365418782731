import { pipe, keys, cond, includes, __, T, take, find, test, pathOr } from 'ramda';

import { COUNTRY_SETTINGS } from 'utils/abroadRegistration';

import i18n from '.';
import { APP_LANGS, DEFAULT_LANGUAGE } from './consts';

const getLangList = (pathname) =>
  pipe(
    keys,
    find((key) => test(new RegExp(`\\${key}`), pathname)),
    (abroadRegisterPath) => pathOr(APP_LANGS, [abroadRegisterPath, 'languages'], COUNTRY_SETTINGS)
  )(COUNTRY_SETTINGS);

const checkLanguage = (list) => {
  cond([
    [includes(__, list), T],
    [pipe(take(2), includes(__, list)), pipe(take(2), i18n.changeLanguage.bind(i18n))],
    [T, () => i18n.changeLanguage(DEFAULT_LANGUAGE)],
  ])(i18n.language);
};

export const defineLanguage = pipe(getLangList, checkLanguage);
