import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Outlet } from 'react-router-dom';

import { useAppActions, useInitBrowserCountry } from 'store/app';
import { useSession } from 'store/user';
import { useInitOrganization } from 'store/organizations';
import { useLanguageChecker } from 'locale/hooks';
import { routesMapping } from '../utils';
import { Spinner } from '../styles';

const Layout = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation(['messages', 'app']);
  const { setAppError } = useAppActions();
  const sessionAlerts = useSession();
  const orgAlerts = useInitOrganization();
  const browserCountryAlerts = useInitBrowserCountry();
  const { isPrivate, layout: Container, title } = useMemo(() => routesMapping(pathname) || {}, [pathname]);

  useLanguageChecker();

  useEffect(() => {
    if (sessionAlerts.error || orgAlerts.error) setAppError(t(sessionAlerts.error || orgAlerts.error));
  }, [orgAlerts.error, sessionAlerts.error, setAppError, t]);

  useEffect(() => {
    if (window.scrollY && window.scrollTo) window.scrollTo(0, 0);
  }, [pathname]);

  if (sessionAlerts.loading || (isPrivate && orgAlerts.loading) || browserCountryAlerts.loading) return <Spinner />;

  return Container ? <Container title={t(title, { ns: 'app' })} /> : <Outlet />;
};

export default Layout;
