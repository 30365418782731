import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { map, omit } from 'ramda';

import { extractErrorsFromYup } from 'utils/helper';

import { useAsyncState } from './useAsyncState';

export const useViaYup = (schema, action, locale) => {
  const { t } = useTranslation(locale);
  const [errors, setErrors] = useAsyncState({});

  const validate = useCallback(
    async (val) => {
      try {
        const data = await schema.validate(val, { abortEarly: false });

        if (data && typeof action === 'function') action(data);
      } catch (e) {
        if (e?.name === 'ValidationError') {
          setErrors(extractErrorsFromYup(e));
        } else {
          console.error('Error is in the useViaYup hook, check provided action', e); // eslint-disable-line no-console
        }
      }
    },
    [action, schema, setErrors]
  );

  return {
    validate,
    errors: useMemo(() => (locale ? map(t)(errors) : errors), [errors, locale, t]),
    resetError: useCallback((field) => errors[field] && setErrors(omit([field], errors)), [errors, setErrors]),
    resetErrors: useCallback(() => setErrors({}), [setErrors]),
  };
};
