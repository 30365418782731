export const URL = process.env.REACT_APP_URL;
export const HOSTNAME = process.env.REACT_APP_HOSTNAME;
export const API_URL = process.env.REACT_APP_API_URL;
export const MESSENGER_URL = process.env.REACT_APP_MESSENGER_URL;
export const STRIPE_TOKEN = process.env.REACT_APP_STRIPE_TOKEN;

export const SHORT_HOST = HOSTNAME.replace(/^https?:\/\//, '');

export const APPLICATION_TOKEN = 'ce0d1b978ceb91f74071ae2143724602';

export const BUGSNAG_API_KEY = '614f1f6ebdf46c3a09ba38ec48d66bf5';

export const DEFAULT_ERROR = 'Oops, something went wrong. Help us improve your experience by sending a problem report.';

export const TOKEN = 'token';
export const TOKEN_2FA = 'token2FA';

export const SEARCH = 'search';
export const PAGINATION = 'pagination';
export const COUNT = 'count';
export const HAS_MORE = 'hasMore';
export const START_AFTER = 'startAfter';
export const LIMIT = 'limit';
export const SORT = 'sort';
export const SORT_BY = 'sortBy';
export const SORT_DIR = 'sortDir';
export const ASC = 'asc';
export const DESC = 'desc';
export const EXPAND = 'expand[]';
export const FIELDS = 'fields[]';

export const META = 'meta';
export const CREATED = 'created';
export const MODIFIED = 'modified';
export const OWNER = 'owner';

export const ID = '_id';
export const NAME = 'name';

export const DATE = 'date';
export const DATE_LT = 'date[lt]';
export const DATE_LTE = 'date[lte]';
export const DATE_GT = 'date[gt]';
export const DATE_GTE = 'date[gte]';

export const SUB_SENDER = '_sender';
export const SUB_ORGANIZATION = '_organization';
export const SUB_LICENSE = '_license';
export const SUB_PHYSICIAN = '_physician';
export const SUB_USER = '_user';
export const SUB_PRODUCT = '_product';

export const TEST_CREATE = 'TEST_CREATE';
export const PATIENT_UPDATE = 'PATIENT_UPDATE';
export const INJECTION_CREATE = 'INJECTION_CREATE';
