import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './rootSaga';
import reducers from './reducers';

const sagaMiddleware = createSagaMiddleware();

const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: reduxDevTools } = window;
const composeEnhancers = (process.env.NODE_ENV === 'development' && reduxDevTools) || compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export default store;
