import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { CenteredContainer, Title, Text, Restart } from './styles';

const Error = ({ isSuspense = false }) => {
  const [counter, setCounter] = useState(10);

  const onReload = useCallback(() => window.location.reload(), []);

  useEffect(() => {
    if (isSuspense) return () => null;

    const timer = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [counter, isSuspense, setCounter]);

  useEffect(() => {
    if (counter === 0) onReload();
  }, [counter, onReload]);

  return (
    <CenteredContainer>
      <Title>Oops :(</Title>
      <Text>
        {isSuspense
          ? "We couldn't connect to the server. Please check your internet connection."
          : 'A problem has occurred on your computer. We have collected the error information and transferred it anonymously to our error analysis software. We will try to fix the problem as soon as possible.'}
      </Text>
      <Restart type="button" onClick={onReload} disabled={!isSuspense}>
        {isSuspense ? 'Reload page' : `The application will restart in ${counter}s.`}
      </Restart>
    </CenteredContainer>
  );
};

Error.propTypes = { isSuspense: PropTypes.bool };

export default Error;
