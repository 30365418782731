import React from 'react';
import { Outlet } from 'react-router-dom';

import ExternalHeader from '../ExternalHeader';
import Footer from '../Footer';

import { GlobalStyles, Main } from './styles';

const VideoLayout = () => (
  <>
    <GlobalStyles />
    <ExternalHeader />
    <Main>
      <Outlet />
    </Main>
    <Footer />
  </>
);

export default VideoLayout;
