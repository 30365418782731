import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_PATIENT,
  RESET_PATIENT,
  UPDATE_LICENSES,
  UPDATE_HEALTH_RECORD,
  UPDATE_ALARMS_FILTERS_BY_TYPE,
  FETCH_PATIENTS,
  FETCH_PATIENT,
  FETCH_PATIENT_TESTS,
  FETCH_PATIENT_INJECTIONS,
  CREATE_PATIENT_INJECTION,
  SAVE_PATIENT_INJECTION,
  CREATE_PATIENT,
  SAVE_PATIENT,
  DELETE_PATIENT,
  FETCH_PATIENTS_DASHBOARD,
  CREATE_PATIENT_LABEL,
  EXTEND_LICENSE,
  UPDATE_LICENSE,
  SAVE_HEALTH_RECORD,
  CREATE_PATIENT_REPORT,
  VALIDATE_VOUCHER,
  CREATE_INVITE,
  CREATE_TESTS_SHEET,
  CREATE_INJECTIONS_SHEET,
  FETCH_ALARM_PATIENTS,
  FETCH_ALARM_HISTORY,
  FETCH_PATIENT_SURVEY,
  FETCH_ALARMS_BY_TYPE,
  FETCH_APPOINTMENT_PATIENTS,
  CREATE_APPOINTMENT,
  FETCH_PROMS,
  FETCH_MEASUREMENTS,
  SAVE_MEASUREMENT,
  CREATE_MEASUREMENT,
} from './types';

export const updatePatient = createAction(UPDATE_PATIENT);
export const resetPatient = createAction(RESET_PATIENT);
export const updateHealthRecord = createAction(UPDATE_HEALTH_RECORD);
export const updateLicenses = createAction(UPDATE_LICENSES);
export const updateAlarmsFiltersByType = createAction(UPDATE_ALARMS_FILTERS_BY_TYPE);

export const fetchPatients = createAlertAction(FETCH_PATIENTS);
export const fetchPatient = createAlertAction(FETCH_PATIENT);
export const fetchPatientTests = createAlertAction(FETCH_PATIENT_TESTS);
export const fetchPatientInjections = createAlertAction(FETCH_PATIENT_INJECTIONS);
export const createPatientInjection = createAlertAction(CREATE_PATIENT_INJECTION);
export const savePatientInjection = createAlertAction(SAVE_PATIENT_INJECTION);
export const createPatient = createAlertAction(CREATE_PATIENT);
export const savePatient = createAlertAction(SAVE_PATIENT);
export const deletePatient = createAlertAction(DELETE_PATIENT);
export const fetchPatientsDashboard = createAlertAction(FETCH_PATIENTS_DASHBOARD);
export const createPatientLabel = createAlertAction(CREATE_PATIENT_LABEL);
export const extendLicense = createAlertAction(EXTEND_LICENSE);
export const updateLicense = createAlertAction(UPDATE_LICENSE);
export const saveHealthRecord = createAlertAction(SAVE_HEALTH_RECORD);
export const createPatientReport = createAlertAction(CREATE_PATIENT_REPORT);
export const validateVoucher = createAlertAction(VALIDATE_VOUCHER);
export const createInvite = createAlertAction(CREATE_INVITE);
export const createTestsSheet = createAlertAction(CREATE_TESTS_SHEET);
export const createInjectionsSheet = createAlertAction(CREATE_INJECTIONS_SHEET);
export const fetchAlarmPatients = createAlertAction(FETCH_ALARM_PATIENTS);
export const fetchAlarmHistory = createAlertAction(FETCH_ALARM_HISTORY);
export const fetchPatientSurvey = createAlertAction(FETCH_PATIENT_SURVEY);
export const fetchAlarmsByType = createAlertAction(FETCH_ALARMS_BY_TYPE);
export const fetchAppointmentPatients = createAlertAction(FETCH_APPOINTMENT_PATIENTS);
export const createAppointment = createAlertAction(CREATE_APPOINTMENT);
export const fetchProms = createAlertAction(FETCH_PROMS);
export const fetchMeasurements = createAlertAction(FETCH_MEASUREMENTS);
export const saveMeasurement = createAlertAction(SAVE_MEASUREMENT);
export const createMeasurement = createAlertAction(CREATE_MEASUREMENT);
