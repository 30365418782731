import { handleActions } from 'redux-actions';

import { updateToken } from 'api';

import { UPDATE_USER, LOGOUT } from './types';

export default handleActions(
  {
    [UPDATE_USER]: (state, { payload }) => ({ ...state, ...payload }),
    [LOGOUT]: () => {
      updateToken();
      return {};
    },
  },
  {}
);
