import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { fontStyles } from 'theme';

export const Container = styled.header`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  position: sticky;
  top: 0;
  max-width: 100%;
  background: var(--color-white);
  z-index: 10;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  &:nth-child(2) {
    justify-content: start;
    padding: 20px 30px;
  }
  &:last-of-type {
    justify-content: end;
    padding: 20px 30px 20px 0;
  }
`;

const getLinkStyle = ({ $fontStyle }) => `
  height: 50px;
  border: 2px solid var(--color-green-active);
  border-radius: 25px;
  background-color: var(--color-green-active);
  ${fontStyles[$fontStyle] || fontStyles.font25}
  line-height: 46px;
  text-align: center;
  text-decoration: none;
  padding: 0 15px;
  @media (hover) {
    &:hover {
      background-color: var(--color-white);
    }
  }
  &.active {
    background-color: var(--color-white);
  }
`;

export const Link = styled(NavLink)`
  ${getLinkStyle}
`;
