import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_ORGANIZATION,
  RESET_ORGANIZATION,
  UPDATE_TEAM,
  UPDATE_CARD_DATA,
  FETCH_ORGANIZATION,
  CREATE_ORGANIZATION,
  ACTIVATE_ORGANIZATION,
  SAVE_ORGANIZATION,
  FETCH_ORGANIZATION_DASHBOARD,
  FETCH_TEAM,
  CREATE_CARD_SECRET,
  FETCH_PAYMENT_HISTORY,
  FETCH_PRODUCTS,
  CREATE_PURCHASE,
} from './types';

export const updateOrganization = createAction(UPDATE_ORGANIZATION);
export const resetOrganization = createAction(RESET_ORGANIZATION);
export const updateTeam = createAction(UPDATE_TEAM);
export const updateCard = createAction(UPDATE_CARD_DATA);

export const fetchOrganization = createAlertAction(FETCH_ORGANIZATION);
export const createOrganization = createAlertAction(CREATE_ORGANIZATION);
export const activateOrganization = createAlertAction(ACTIVATE_ORGANIZATION);
export const saveOrganization = createAlertAction(SAVE_ORGANIZATION);
export const fetchOrganizationDashboard = createAlertAction(FETCH_ORGANIZATION_DASHBOARD);
export const fetchTeam = createAlertAction(FETCH_TEAM);
export const createCardSecret = createAlertAction(CREATE_CARD_SECRET);
export const fetchPaymentHistory = createAlertAction(FETCH_PAYMENT_HISTORY);
export const fetchProducts = createAlertAction(FETCH_PRODUCTS);
export const createPurchase = createAlertAction(CREATE_PURCHASE);
