import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useIsLoggedIn } from 'store/user';
import { Spinner } from '../styles';

import ErrorBoundary from './ErrorBoundary';

const SuspenseRoute = ({ component: Component, isPrivate = false }) => {
  const isLoggedIn = useIsLoggedIn();

  if (isPrivate && !isLoggedIn) return <Navigate to="/login" replace />;

  return (
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <Component />
      </Suspense>
    </ErrorBoundary>
  );
};

SuspenseRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isPrivate: PropTypes.bool,
};

export default SuspenseRoute;
