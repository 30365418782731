import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { APP_LANGS } from 'locale/consts';

import { Container, Button, Text } from './styles';

const LangSwitcher = () => {
  const { i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const handleClick = useCallback(({ currentTarget }) => i18n.changeLanguage(currentTarget.value), [i18n]);

  const onHoverStart = useCallback(() => setIsVisible(true), []);
  const onHoverEnd = useCallback(() => setIsVisible(false), []);

  return (
    <Container $current={i18n.language} onMouseLeave={onHoverEnd}>
      {APP_LANGS.map((lng) => (
        <Button
          key={lng}
          type="button"
          value={lng}
          onClick={handleClick}
          disabled={i18n.language === lng}
          $isVisible={isVisible}
          onMouseOver={onHoverStart}
        >
          <Text>{lng}</Text>
        </Button>
      ))}
    </Container>
  );
};

export default LangSwitcher;
