import { useEffect } from 'react';

import { wrapActions, wrapSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';

import * as actions from './actions';
import { getAppError, getLatestNewsDate } from './selectors';

export const useAppActions = wrapActions({ ...actions });

export const useAppError = wrapSelector(getAppError);
export const useLatestNewsDate = wrapSelector(getLatestNewsDate);

export const useInitBrowserCountry = () => {
  const { defineBrowserCountry } = useAppActions();
  const { id, action, loading, success } = useAlerts(defineBrowserCountry);

  useEffect(() => {
    action();
  }, [action]);

  return { success, loading: !id || loading };
};
