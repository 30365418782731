import { all, spawn } from 'redux-saga/effects';

import watchApp from 'store/app/sagas';
import watchUser from 'store/user/sagas';
import watchOrganization from 'store/organizations/sagas';
import watchPatients from 'store/patients/sagas';

export default function* watcher() {
  yield all([spawn(watchApp), spawn(watchUser), spawn(watchOrganization), spawn(watchPatients)]);
}
