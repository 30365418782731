export const FEATURE_NAME = 'user';

export const ROLES = 'roles';
export const ADMIN_ROLE = 'alleye-admin';
export const PHYSICIAN_ROLE = 'alleye-physician';
export const ASSISTANT_ROLE = 'alleye-assistant';
export const OPTOMETRIST_ROLE = 'alleye-optometrist';
export const OPTICIAN_ROLE = 'alleye-optician';
export const PHARMACIST_ROLE = 'alleye-pharmacist';
export const REGISTER_ROLE = 'alleye-registrar';
export const OTHER_ROLE = 'alleye-other';
export const ALLEYE_ROLE = 'alleye';

export const EMAIL = 'email';
export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const BIRTH = 'birthday';
export const LANGUAGE = 'language';
export const MOBILE = 'mobile';
export const MOBILE_COUNTRY_CODE = 'mobileCountryCode';
export const HOSPITAL = 'hospital';
export const TERMS_ACCEPTED = 'termsAccepted';
export const PRIVACY_ACCEPTED = 'privacyAccepted';
export const TERMS_USE_ACCEPTED = 'termsUseAccepted';
export const TWO_FACTOR_ENABLED = 'twoFactorEnabled';
export const DEPARTMENT = 'department';
export const STATUS = 'status';
export const TITLE = 'title';
export const MONTHLY_REPORT = 'monthlyReport';

export const PASSWORD = 'password';
export const NEXT_PASSWORD = 'nextPassword';

export const PAYLOAD = 'payload';
export const HOME_MONITORING_CONSENT = 'homeMonitoringConsent';
export const RESEARCH_CONSENT = 'researchConsent';
export const CODE = 'code';
export const AVAILABLE = 'available';
