import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { ReactComponent as DefIcon } from 'assets/svg-icons/triangle.svg';

const linkStyle = `
  display: flex;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-black);
  @media (max-width: 991px) {
    margin-top: 10px;
  }
`;

export const Link = styled(NavLink)`
  ${linkStyle}
  &.active {
    color: var(--color-grey-text);
  }
`;

export const A = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  ${linkStyle}
`;

export const IconWrap = styled.div`
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--color-white);
  color: var(--color-green-active);
  @media (hover) {
    ${Link}:hover>&, ${A}:hover>& {
      background-color: var(--color-green-active);
      color: var(--color-black);
    }
  }
  ${Link}:active>&, ${Link}.active>&, ${A}:active>& {
    background-color: var(--color-green-active);
    color: var(--color-black);
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

export const Icon = styled(DefIcon)`
  width: 25px;
  height: 25px;
  color: inherit;
  transform: rotate(-90deg);
  & > path {
    color: inherit;
    fill: currentColor;
  }
`;

export const Text = styled.p`
  font: var(--font20);
  color: inherit;
`;
