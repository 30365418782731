// languages
export const EN = 'en';
export const DE = 'de';
export const ZH = 'zh';
export const MS = 'ms';
export const TA = 'ta';
export const AR = 'ar';
export const PL = 'en'; // TODO have to change after translate (locale + content + LANG_LABELS) to 'pl'
export const IT = 'it';

// countries
export const CH = 'CH';
export const GB = 'GB';
export const SG = 'SG';
export const UAE = 'AE';
export const DEU = 'DE';
export const POL = 'PL';
export const US = 'US';
export const ITA = 'IT';

export const COUNTRY_BY_LANGUAGE = {
  [EN]: GB,
  [DE]: CH,
  [IT]: ITA,
  [ZH]: SG,
  [AR]: UAE,
  [PL]: POL,
};

export const DEFAULT_LANGUAGE = EN;

export const LANG_LABELS = [
  { id: EN, name: 'English' },
  { id: DE, name: 'Deutsch' },
  { id: ZH, name: '中文' },
  { id: MS, name: 'Bahasa Melayu' },
  { id: TA, name: 'தமிழ்' },
  { id: AR, name: 'العربية' },
];

export const RTL_LANGS = [AR];

export const APP_LANGS = [EN, DE, IT];
