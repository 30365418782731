import { createGlobalStyle } from 'styled-components';

import nimbusSanLBold from 'assets/fonts/NimbusSanL-Bold.ttf';
import nimbusSanLRegu from 'assets/fonts/NimbusSanL-Regu.ttf';
import nimbusSanLReguItal from 'assets/fonts/NimbusSanL-ReguItal.ttf';
import notoSansRegularWoff from 'assets/fonts/noto-sans-regular.woff';
import notoSansRegularWoff2 from 'assets/fonts/noto-sans-regular.woff2';
import notoSansBoldWoff from 'assets/fonts/noto-sans-bold.woff';
import notoSansBoldWoff2 from 'assets/fonts/noto-sans-bold.woff2';

export default createGlobalStyle`
  @font-face {
    font-display: swap;
    font-family: NimbusSanL-Regu;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    src: url(${nimbusSanLRegu});
  }
  @font-face {
    font-display: swap;
    font-family: NimbusSanL-ReguItal;
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    src: url(${nimbusSanLReguItal});
  }
  @font-face {
    font-display: swap;
    font-family: NimbusSanL-Bold;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    src: url(${nimbusSanLBold});
  }
  @font-face {
    font-display: swap;
    font-family: NotoSans-Regular;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    src: url(${notoSansRegularWoff}) format("woff"), url(${notoSansRegularWoff2}) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: NotoSans-Bold;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    src: url(${notoSansBoldWoff}) format("woff"), url(${notoSansBoldWoff2}) format("woff2");
  }
  :root {
    --color-black: #000;
    --color-white: #fff;
    --color-white-rpg: 255, 255, 255;
    --color-yellow: #fdd303;
    --color-status-default: #e8e6e1;

    --color-green-text: #07aa6d;
    --color-green-font: #068854;
    --color-green-active: #21ea9f;
    --color-green-disabled: #abffd9;
    --color-green-hover: #40cc8c;
    --color-green-pressed: #04ffa1;

    --color-red-active: #ff4a4a;
    --color-red-disabled: #ff8d8d;
    --color-red-hover: #ff8d8d;

    --color-grey: #ccc;
    --color-grey-text: #949494;
    --color-grey-active: #e9e9e9;
    --color-grey-disabled: #e9e9e9;
    --color-grey-hover: #cecece;
    --color-grey-dark: #595959;
    --color-greyF3: #f3f3f3;

    --color-purple-active: #9786ff;
    --color-purple-hover: #9786ff;
    --color-purple-pressed: #9786ff;

    --color-drug-avastin: #c562af;
    --color-drug-beovu: #00c9ba;
    --color-drug-eylea: #fdd301;
    --color-drug-eyleaHd: #ff9900;
    --color-drug-lucentis: #296ef5;
    --color-drug-ozurdex: #ff91ce;
    --color-drug-vabysmo: #c7fd01;
    --color-drug-others: #a0a0a0;
    --color-drug-byooviz: #70f6ff;
    --color-drug-cimerli: #d2dcb5;

    --color-blue: #434ae3;
    --color-orange: #f96d1f;
    --color-grey-blue: #ebeff3;
    --color-red: #e20000;

    --font12: 400 12px 'NimbusSanL-Regu', sans-serif;
    --font12-bold: 700 12px 'NimbusSanL-Bold', sans-serif;
    --font15: 400 15px 'NimbusSanL-Regu', sans-serif;
    --font15-bold: 700 15px 'NimbusSanL-Bold', sans-serif;
    --font17: 400 17px 'NimbusSanL-Regu', sans-serif;
    --font17-bold: 700 17px 'NimbusSanL-Bold', sans-serif;
    --font20: 400 20px 'NimbusSanL-Regu', sans-serif;
    --font20-bold: 700 20px 'NimbusSanL-Bold', sans-serif;
    --font25: 400 25px 'NimbusSanL-Regu', sans-serif;
    --font25-bold: 700 25px 'NimbusSanL-Bold', sans-serif;
    --font26: 400 26px 'NimbusSanL-Regu', sans-serif;
    --font26-bold: 700 26px 'NimbusSanL-Bold', sans-serif;
    --font30: 400 30px 'NimbusSanL-Regu', sans-serif;
    --font30-bold: 700 30px 'NimbusSanL-Bold', sans-serif;
    --font35: 400 35px 'NimbusSanL-Regu', sans-serif;
    --font35-bold: 700 35px 'NimbusSanL-Bold', sans-serif;
    --font79: 400 79px 'NimbusSanL-Regu', sans-serif;
    --font79-bold: 700 79px 'NimbusSanL-Bold', sans-serif;
    --font120: 400 120px 'NimbusSanL-Regu', sans-serif;
    --font120-bold: 700 120px 'NimbusSanL-Bold', sans-serif;

    --shadow-default: 0 2px 11px 0 rgba(200, 199, 199, 0.5);

    --header-height: 82px;

    --vh: 1dvh;
  }
  @supports not (height: 1dvh) {
    --vh: 1vh;
  }
  @media (max-width: 991px) {
    :root {
      --header-height: 72px;
    }
  }
  * {
    margin: 0;
    padding: 0;
    border: none;
    font-family: "NimbusSanL-Regu", sans-serif;
    font-size: 17px;
    color: var(--color-black);
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-overflow-scrolling: touch;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }
  html, body {
    height: 100%;
    min-width: 350px;
  }
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  button, input, select, textarea {
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    &:disabled {
      -webkit-text-fill-color: currentColor;
      opacity: 1;
      cursor: not-allowed;
    }
  }
  .xaxis text,
  .yaxis text {
    font-weight: 700;
    stroke: none;
    font-size: 14px;
  }
`;
