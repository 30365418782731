import { lazy } from 'react';
import { matchPath } from 'react-router-dom';
import { cond, T } from 'ramda';

import { COUNTRY_SETTINGS } from 'utils/abroadRegistration';

import ExternalLayout from './ExternalLayout';
import ImageLayout from './ImageLayout';
import InternalLayout from './InternalLayout';
import VideoLayout from './VideoLayout';

const Reset = lazy(() => import('pages/Reset'));
const AbroadRegistration = lazy(() => import('pages/AbroadRegistration'));

const abroadRegistrationRoutes = Object.keys(COUNTRY_SETTINGS);

export const ROUTES = [
  {
    path: '/product-alleye',
    component: lazy(() => import('pages/Product')),
    layout: ExternalLayout,
    title: 'alleye',
    meta: {
      title: 'About the Alleye test',
      description:
        'The Alleye app is designed for the detection and monitoring of retinal diseases, including age-related macular degeneration and diabetic macular edema (DME). Alleye makes it possible to carry out a simple self-test at home on a regular basis.',
    },
  },
  {
    path: '/imprint',
    component: lazy(() => import('pages/Imprint')),
    layout: ExternalLayout,
    title: 'Imprint',
    meta: {
      title: 'Imprint',
      description:
        'Oculocare medical AG aims to help patients with eye diseases through Alleye, a global technology truly for all eye. Oculocare medical is certified under ISO 13485:2016. With the ISO 13485 certification, Oculocare medical is allowed to certify Alleye as a class I medical device. Alleye received FDA 510(k) clearance.',
    },
  },
  {
    path: '/privacy',
    component: lazy(() => import('pages/Privacy')),
    layout: ExternalLayout,
    title: 'Privacy',
    meta: {
      title: 'Privacy Information',
      withoutIndexing: true,
    },
  },
  {
    path: '/accessibility',
    component: lazy(() => import('pages/Accessibility')),
    layout: ExternalLayout,
    title: 'Accessibility statement',
    meta: {
      title: 'Accessibility',
      description: 'Oculocare medical is committed to making its digital technologies accessible.',
    },
  },
  {
    path: '/customer-privacy',
    component: lazy(() => import('pages/CustomerPrivacy')),
    layout: ExternalLayout,
    title: 'App privacy',
    meta: {
      title: 'Privacy Information for the Alleye User',
      withoutIndexing: true,
    },
  },
  {
    path: '/customer-terms',
    component: lazy(() => import('pages/CustomerTerms')),
    layout: ExternalLayout,
    title: 'Terms of App',
    meta: {
      title: 'Terms and Conditions for the Alleye User',
      withoutIndexing: true,
    },
  },
  {
    path: '/reset',
    component: Reset,
    layout: ExternalLayout,
    meta: {
      title: 'Reset Password',
      withoutIndexing: true,
    },
  },
  {
    path: '/activate',
    component: Reset,
    layout: ExternalLayout,
    meta: {
      title: 'Activate Account',
      withoutIndexing: true,
    },
  },
  {
    path: '/register',
    component: lazy(() => import('pages/Register')),
    layout: ExternalLayout,
    title: 'Register as an Alleye provider',
    meta: {
      title: 'Become an Alleye provider',
      withoutIndexing: true,
    },
  },
  {
    path: '/alleye-map',
    component: lazy(() => import('pages/CountriesMap')),
    meta: {
      title: 'Map',
      description: 'Over the years, Alleye has expanded its geographical coverage and is used in over 30 countries worldwide.',
    },
  },
  {
    path: '/research',
    component: lazy(() => import('pages/Research')),
    layout: ExternalLayout,
    meta: {
      title: 'Research',
      description: 'An overview of the many scientific publications about Alleye.',
    },
  },
  {
    path: '/download',
    component: lazy(() => import('pages/Download')),
    layout: ImageLayout,
    meta: {
      title: 'Download the App',
      description: 'Get started with Alleye – the digital companion for your eye health.',
    },
  },
  {
    path: '/faq',
    component: lazy(() => import('pages/Faq')),
    layout: ExternalLayout,
    meta: {
      title: 'FAQ',
      description:
        'Alleye support is here to help. Learn more about popular topics and find resources that will help you with your Alleye experience.',
    },
  },
  {
    path: '/provider',
    component: lazy(() => import('pages/Provider')),
    meta: {
      title: 'For Healthcare Professionals',
      description: 'Alleye is the digital companion for your patients’ eye health.',
    },
  },
  {
    path: '/login',
    component: lazy(() => import('pages/Login')),
    layout: ImageLayout,
    meta: {
      title: 'Log in',
    },
  },
  {
    path: '/become-provider',
    component: lazy(() => import('pages/BecomeProvider')),
    layout: ImageLayout,
    meta: {
      title: 'Become a Provider',
      description:
        'Become an Alleye provider and offer the Alleye app to patients with retinal disease, including age-related macular degeneration and diabetic macular edema (DME).',
    },
  },
  {
    path: '/news',
    component: lazy(() => import('pages/News')),
    layout: ExternalLayout,
    title: 'news',
    meta: {
      title: 'Newsroom',
      description:
        'Alleye newsroom is the source for news about Alleye. Read our latest research publications, get updates, and watch videos.',
    },
  },
  { path: '/news/:newsId', component: lazy(() => import('pages/NewsItem')), layout: ExternalLayout, meta: null },
  {
    path: '/service-report',
    component: lazy(() => import('pages/ServiceReport')),
    layout: ExternalLayout,
    title: 'Service report',
    meta: {
      title: 'Service Report',
      description: 'Annual service report on the use of the Alleye app.',
    },
  },
  {
    path: '/contact',
    component: lazy(() => import('pages/Contact')),
    layout: ExternalLayout,
    title: 'Contact Us',
    meta: {
      title: 'Contact',
      description: 'Alleye is here to help. Contact Alleye support by sending us a message.',
    },
  },
  {
    path: '/app-explained',
    component: lazy(() => import('pages/AppExplained')),
    layout: VideoLayout,
    meta: {
      title: 'Alleye app',
      description: 'Alleye app explaining',
      withoutIndexing: true,
    },
  },
  {
    path: '/leaflet',
    component: lazy(() => import('pages/PdfFiles')),
    meta: {
      title: 'Alleye app',
      withoutIndexing: true,
    },
  },
  {
    path: '/downloads/:id',
    component: lazy(() => import('pages/PdfFiles')),
    meta: {
      title: 'Alleye app',
      withoutIndexing: true,
    },
  },
  {
    path: '/oculocare',
    component: lazy(() => import('pages/Oculocare')),
    layout: ExternalLayout,
    title: 'Leadership',
    meta: {
      title: 'Oculocare medical AG',
      description:
        'Oculocare medical is committed to build digital innovations that save people from blindness and vision impairment. The company works at the intersection of ophthalmology, statistics, design and technology. Founded in 2015, Oculocare is a subsidiary of medignition.',
    },
  },
  ...abroadRegistrationRoutes.map((path) => ({
    path: `${path}/*`,
    component: AbroadRegistration,
    meta: {
      title: 'Become an Alleye User',
      withoutIndexing: true,
    },
  })),

  {
    path: '/dashboard',
    component: lazy(() => import('pages/Dashboard')),
    isPrivate: true,
    layout: InternalLayout,
    meta: {
      title: 'Dashboard',
      withoutIndexing: true,
    },
  },
  {
    path: '/dashboard/credits',
    component: lazy(() => import('pages/Credits')),
    isPrivate: true,
    layout: InternalLayout,
    meta: {
      title: 'Dashboard',
      withoutIndexing: true,
    },
  },
  {
    path: '/account',
    component: lazy(() => import('pages/Account')),
    isPrivate: true,
    layout: InternalLayout,
    meta: {
      title: 'Provider Account',
      withoutIndexing: true,
    },
  },
  {
    path: '/patients',
    component: lazy(() => import('pages/Patients')),
    isPrivate: true,
    layout: InternalLayout,
    meta: {
      title: 'Alleye Users',
      withoutIndexing: true,
    },
  },
  {
    path: '/patients/edit/:patientId',
    component: lazy(() => import('pages/PatientEdit')),
    isPrivate: true,
    layout: InternalLayout,
    meta: null,
  },
  {
    path: '/patients/new/:patientId',
    component: lazy(() => import('pages/PatientNew')),
    isPrivate: true,
    layout: InternalLayout,
    meta: {
      title: 'New Alleye User',
      withoutIndexing: true,
    },
  },
  {
    path: '/profile',
    component: lazy(() => import('pages/Profile')),
    isPrivate: true,
    layout: InternalLayout,
    meta: {
      title: 'Profile',
      withoutIndexing: true,
    },
  },
  {
    path: '/appointment',
    component: lazy(() => import('pages/Appointment')),
    isPrivate: true,
    layout: InternalLayout,
    meta: {
      title: 'Appointment',
      withoutIndexing: true,
    },
  },
  {
    path: '/bugreport',
    component: lazy(() => import('pages/BugReport')),
    isPrivate: true,
    layout: ExternalLayout,
    title: 'Report a problem',
    meta: {
      title: 'Report a problem',
      withoutIndexing: true,
    },
  },
];

export const routesMapping = cond([
  ...ROUTES.map((route) => [(currentPath) => matchPath(route.path, currentPath), () => route]),
  [T, () => ({})],
]);
