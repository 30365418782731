import React from 'react';

import { useMobileDetect } from 'hooks';

import Mobile from './Mobile';
import Desktop from './Desktop';

const Header = () => (useMobileDetect() ? <Mobile /> : <Desktop />);

export default Header;
