import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { wrapActions, wrapSelector, wrapMultiSelector, wrapSelectorWithArg } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { useIsAlleye } from 'store/organizations';
import { ID } from 'api/consts';

import * as actions from './actions';
import {
  getPatient,
  getPatientProp,
  getPatientLicenses,
  getAlarmsFiltersByType,
  getHealthRecord,
  getIsAlleyeOnePatient,
} from './selectors';

export const usePatientsActions = wrapActions({ ...actions });

export const usePatient = wrapMultiSelector(getPatientProp, getPatient);
export const usePatientLicenses = wrapSelector(getPatientLicenses);
export const useHealthRecord = wrapSelector(getHealthRecord);
export const useAlarmsFiltersByType = wrapSelectorWithArg(getAlarmsFiltersByType);
export const useIsAlleyeOnePatient = wrapSelector(getIsAlleyeOnePatient);

export const usePatients = (params) => {
  const { fetchPatients } = usePatientsActions();
  const { id, action, success, loading, error, resetAlerts } = useAlerts(fetchPatients);

  useEffect(() => {
    action(params);
    return resetAlerts;
  }, [action, params, resetAlerts]);

  return { list: success, loading: loading || !id, error };
};

export const useAlarmPatients = (params) => {
  const isAlleye = useIsAlleye();
  const { fetchAlarmPatients } = usePatientsActions();
  const { id, action, success, loading, error, resetAlerts } = useAlerts(fetchAlarmPatients);

  useEffect(() => {
    if (isAlleye) {
      action(params);

      return resetAlerts;
    }

    return () => null;
  }, [action, isAlleye, params, resetAlerts]);

  return { list: success?.list, count: success?.count || 0, loading: isAlleye && (loading || !id), error };
};

export const useInitPatient = () => {
  const currentId = usePatient(ID);
  const { patientId } = useParams();

  const { fetchPatient, resetPatient } = usePatientsActions();
  const { id, action, loading, success, error } = useAlerts(fetchPatient);

  useEffect(() => {
    if (!id || (!loading && !error && patientId !== currentId)) action(patientId);
  }, [action, currentId, id, loading, patientId, error]);

  useEffect(
    () => () => {
      resetPatient();
    },
    [resetPatient]
  );

  return {
    loading: !(success || error) || loading,
    error,
  };
};
