import React from 'react';

export default () => (
  <svg width="84" height="47" viewBox="0 0 252 141">
    <g>
      <path d=" M 111.40 1.65 C 135.01 -2.49 159.26 3.28 180.66 13.34 C 193.02 19.30 205.04 26.45 214.95 36.05 C 217.81 39.06 221.37 42.43 220.96 46.98 C 220.96 50.64 218.12 53.32 215.85 55.86 C 207.24 64.59 196.79 71.29 185.98 76.96 C 167.84 85.61 148.27 92.17 128.00 92.44 C 107.45 92.23 87.52 85.73 69.19 76.81 C 57.39 70.53 45.80 63.19 37.02 52.95 C 34.20 50.01 33.38 45.20 35.73 41.73 C 39.23 36.65 44.04 32.64 48.84 28.83 C 67.25 15.19 88.80 5.58 111.40 1.65 M 69.16 33.15 C 62.78 37.03 56.44 41.18 51.29 46.65 C 62.95 57.18 76.96 64.86 91.58 70.46 C 103.23 74.72 115.52 77.81 128.00 77.67 C 138.87 77.77 149.61 75.30 159.87 71.85 C 175.42 66.42 190.39 58.66 202.85 47.79 C 205.09 46.58 202.25 44.82 201.28 43.74 C 184.63 30.06 164.36 20.85 143.25 16.69 C 117.53 11.66 91.32 20.43 69.16 33.15 Z" />
      <path d=" M 100.48 41.21 C 101.82 31.52 108.89 23.35 117.84 19.71 C 121.25 27.91 124.04 36.35 127.64 44.46 C 131.07 36.29 134.14 27.97 137.50 19.77 C 147.44 23.54 154.81 33.27 155.24 43.96 C 156.25 57.32 146.06 70.48 132.79 72.58 C 126.79 73.23 120.37 73.08 114.99 70.00 C 104.66 64.78 98.52 52.62 100.48 41.21 Z" />
      <path d=" M 1.11 107.22 C 3.25 107.22 5.40 107.24 7.54 107.26 C 10.27 114.83 12.78 122.48 15.83 129.93 C 18.92 122.47 21.55 114.82 24.36 107.26 C 26.33 107.24 28.30 107.23 30.28 107.23 C 26.42 117.55 22.22 127.74 17.95 137.89 C 16.35 137.87 14.76 137.86 13.16 137.84 C 9.01 127.69 5.05 117.45 1.11 107.22 Z" />
      <path d=" M 33.26 107.21 C 35.07 107.21 36.89 107.21 38.71 107.22 C 38.75 117.43 38.73 127.64 38.72 137.86 C 36.90 137.86 35.07 137.87 33.25 137.87 C 33.23 127.65 33.21 117.43 33.26 107.21 Z" />
      <path d=" M 47.43 108.51 C 52.47 105.02 59.26 106.05 64.22 109.14 C 63.15 110.38 62.14 111.69 60.91 112.78 C 57.96 112.86 55.13 110.61 52.18 111.73 C 49.95 112.41 48.54 115.67 50.40 117.42 C 53.88 120.00 58.66 120.10 61.93 123.03 C 65.78 126.91 64.63 134.18 59.95 136.92 C 54.48 140.18 47.25 138.88 42.50 134.87 C 43.69 133.63 44.86 132.35 46.21 131.28 C 49.19 132.22 52.13 134.55 55.39 133.33 C 58.15 132.75 59.54 128.79 57.14 126.96 C 53.66 124.48 48.89 124.37 45.76 121.32 C 42.34 117.71 43.34 111.21 47.43 108.51 Z" />
      <path d=" M 69.14 107.18 C 70.95 107.20 72.76 107.22 74.57 107.25 C 74.55 117.44 74.54 127.63 74.58 137.82 C 72.76 137.85 70.95 137.87 69.13 137.90 C 69.08 127.66 69.06 117.42 69.14 107.18 Z" />
      <path d=" M 89.37 107.29 C 95.77 105.22 103.84 106.22 108.29 111.70 C 113.69 118.40 113.26 129.79 106.17 135.18 C 99.43 140.51 88.40 139.92 82.74 133.26 C 75.98 125.26 79.01 110.65 89.37 107.29 M 91.35 112.17 C 84.02 115.23 82.95 126.26 88.68 131.33 C 92.10 134.23 97.41 134.39 101.24 132.22 C 106.52 128.84 107.58 120.89 104.16 115.83 C 101.50 111.71 95.77 110.26 91.35 112.17 Z" />
      <path d=" M 116.62 107.20 C 119.04 107.21 121.46 107.22 123.88 107.21 C 128.94 114.57 133.46 122.30 138.70 129.54 C 138.91 122.10 138.72 114.66 138.83 107.22 C 140.68 107.21 142.52 107.21 144.36 107.19 C 144.48 117.43 144.46 127.66 144.38 137.89 C 142.00 137.87 139.63 137.85 137.26 137.82 C 132.14 130.19 127.37 122.33 122.20 114.73 C 122.03 122.43 122.19 130.14 122.09 137.84 C 120.26 137.85 118.43 137.87 116.60 137.89 C 116.52 127.66 116.49 117.43 116.62 107.20 Z" />
    </g>
    <g fill="#d81f27">
      <path d=" M 152.73 108.76 C 157.67 105.12 164.95 105.63 169.69 109.38 C 168.47 110.78 167.23 112.18 166.00 113.58 C 163.87 112.36 161.61 110.93 159.03 111.44 C 156.27 111.36 154.03 114.93 155.83 117.17 C 159.08 120.04 163.96 119.90 167.22 122.79 C 171.37 126.41 170.43 133.96 165.81 136.79 C 160.34 140.13 152.95 139.05 148.20 134.89 C 149.46 133.56 150.75 132.25 152.05 130.94 C 155.03 133.04 159.20 134.98 162.57 132.52 C 164.70 131.01 164.31 127.14 161.76 126.28 C 158.65 124.58 154.94 124.14 152.07 121.99 C 148.05 118.72 148.62 111.73 152.73 108.76 Z" />
      <path d=" M 184.18 107.23 C 185.78 107.22 187.39 107.21 189.00 107.20 C 193.46 117.39 197.79 127.63 202.24 137.82 C 200.14 137.85 198.03 137.86 195.93 137.88 C 194.99 135.64 194.05 133.40 193.09 131.18 C 188.63 131.45 184.02 130.63 179.66 131.59 C 178.61 133.61 177.85 135.77 177.02 137.88 C 174.95 137.86 172.90 137.85 170.84 137.82 C 175.34 127.64 179.71 117.42 184.18 107.23 M 186.37 114.19 C 184.76 118.17 183.21 122.18 181.81 126.25 C 184.89 126.29 187.96 126.29 191.04 126.26 C 189.63 122.18 188.16 118.12 186.37 114.19 Z" />
      <path d=" M 198.88 107.20 C 200.99 107.37 203.31 106.78 205.30 107.66 C 208.24 115.08 210.66 122.71 213.52 130.16 C 216.59 122.60 219.25 114.87 222.15 107.23 C 224.13 107.23 226.12 107.23 228.11 107.25 C 224.10 117.48 219.95 127.65 215.79 137.82 C 214.16 137.85 212.53 137.87 210.91 137.90 C 206.80 127.70 202.76 117.48 198.88 107.20 Z" />
      <path d=" M 230.99 107.23 C 237.81 107.21 244.63 107.25 251.46 107.20 C 251.46 108.88 251.46 110.56 251.45 112.24 C 246.49 112.26 241.53 112.25 236.58 112.26 C 236.57 114.71 236.58 117.17 236.59 119.62 C 241.28 119.61 245.96 119.59 250.65 119.65 C 250.66 121.33 250.68 123.00 250.69 124.68 C 246.00 124.65 241.30 124.65 236.60 124.68 C 236.58 127.40 236.57 130.12 236.57 132.84 C 241.71 132.84 246.86 132.84 252.00 132.84 L 252.00 137.86 C 244.97 137.85 237.94 137.88 230.91 137.84 C 230.92 127.64 230.81 117.43 230.99 107.23 Z" />
    </g>
  </svg>
);
