import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const SET_APP_ERROR = getName('SET_APP_ERROR');
export const UPDATE_LATEST_NEWS_DATE = getName('UPDATE_LATEST_NEWS_DATE');
export const SET_BROWSER_COUNTRY = getName('SET_BROWSER_COUNTRY');

export const UPLOAD_FILE = getName('UPLOAD_FILE');
export const CREATE_BUG_REPORT = getName('CREATE_BUG_REPORT');
export const CREATE_ABROAD_BUG_REPORT = getName('CREATE_ABROAD_BUG_REPORT');
export const FETCH_COUNTRIES = getName('FETCH_COUNTRIES');
export const DEFINE_BROWSER_COUNTRY = getName('DEFINE_BROWSER_COUNTRY');
