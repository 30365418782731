export const FEATURE_NAME = 'organizations';

export const ORGANIZATION = 'organization';
export const TEAM = 'team';

export const ADDRESS = 'address';
export const ZIP_CODE = 'zipCode';
export const CITY = 'city';
export const COUNTRY = 'country';
export const ORG_URL = 'url';
export const LANGUAGE = 'language';
export const TERMS_ACCEPTED = 'termsAccepted';
export const REMINDER = 'reminder';
export const SIGNAL_REMINDER = 'signalReminder';
export const SIGNAL_REMINDER_USER = 'signalReminderUser';
export const SIGN_UP_REMINDER = 'signUpReminder';
export const SIGN_UP_REMINDER_USER = 'signUpReminderUser';
export const LICENSING = 'licensing';
export const KIND = 'kind';
export const REGISTRATION_FIELDS = 'registrationFields';

export const CARD = 'card';
export const BRAND = 'brand';
export const LAST_4 = 'last4';
export const CURRENCY = 'currency';
export const CREDITS = 'credits';
export const PRICE = 'price';
export const PAYMENT_METHOD = 'paymentMethod';
export const STATUS = 'status';

export const USERS = 'users';

export const LICENSING_TYPES = {
  0: 0,
  1: 1,
};
