import styled, { createGlobalStyle } from 'styled-components';

import BackButton from 'components/BackButton';

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: var(--color-grey-blue);
  }
`;

export const Main = styled.main`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: max(130px, 14%) 1fr;
  grid-template-rows: auto 1fr;
  gap: 40px 20px;
  padding: ${({ $isLoggedIn }) => ($isLoggedIn ? '25px' : 'var(--header-height) 25px 25px')};
  overflow: visible;
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    gap: 25px 15px;
    padding: ${({ $isLoggedIn }) => ($isLoggedIn ? '15px' : 'var(--header-height) 15px 15px')};
  }
`;

export const Button = styled(BackButton)`
  align-self: center;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const Title = styled.h1`
  justify-self: start;
  display: flex;
  align-items: center;
  min-height: 40px;
  border-radius: 34px;
  background-color: var(--color-black);
  font: var(--font30);
  color: var(--color-white);
  padding: 0 20px;
  @media (max-width: 991px) {
    min-height: 30px;
    font: var(--font20);
    padding: 0 15px;
  }
`;

export const MarginBox = styled.div`
  @media (max-width: 991px) {
    display: none;
  }
`;
