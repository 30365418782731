import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { APP_LANGS } from 'locale/consts';

import { Container, Button } from './styles';

const LangSwitcher = () => {
  const { i18n } = useTranslation();
  const handleClick = useCallback(({ currentTarget }) => i18n.changeLanguage(currentTarget.value), [i18n]);

  return (
    <Container>
      {APP_LANGS.map((lng) => (
        <Button key={lng} type="button" value={lng} onClick={handleClick} disabled={i18n.language === lng}>
          {lng}
        </Button>
      ))}
    </Container>
  );
};

export default LangSwitcher;
