export default {
  font12: `
    font-family: "NimbusSanL-Regu", sans-serif;
    font-size: 12px;
    font-weight: normal;
  `,
  font14: `
    font-family: "NimbusSanL-Regu", sans-serif;
    font-size: 14px;
    line-height: 1.11;
    letter-spacing: 0.39px;
    font-weight: normal;
  `,
  font17: `
    font-family: "NimbusSanL-Regu", sans-serif;
    font-size: 17px;
    line-height: 1.24;
    letter-spacing: -0.12px;
    font-weight: normal;
  `,
  font17bold: `
    font-family: "NimbusSanL-Bold", sans-serif;
    font-size: 17px;
    line-height: 1.24;
    letter-spacing: -0.12px;
  `,
  font17ital: `
    font-family: "NimbusSanL-ReguItal", sans-serif;
    font-size: 17px;
    line-height: 1.24;
    letter-spacing: -0.12px;
  `,
  font25: `
    font-family: "NimbusSanL-Regu", sans-serif;
    font-size: 25px;
    font-weight: normal;
  `,
  font34: `
    font-family: "NimbusSanL-Regu", sans-serif;
    font-size: 34px;
    font-weight: normal;
  `,
};
