import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import Bowser from 'bowser';
import { StyleSheetManager } from 'styled-components';

import { BUGSNAG_API_KEY } from 'api/consts';
import 'locale';
import packageJson from '../package.json';

import * as serviceWorker from './serviceWorkerRegistration';

const isOldBrowser = Bowser.getParser(window.navigator.userAgent).satisfies({
  edge: '<79',
  ie: '<=11',
});

if (isOldBrowser) {
  require('utils/warnIEPage');
} else {
  const Root = require('components/Root').default;
  const Error = require('components/Error').default;
  const store = require('store').default;

  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    appType: 'client',
    appVersion: packageJson.version,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production'],
  });

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

  createRoot(document.getElementById('root')).render(
    <ErrorBoundary FallbackComponent={process.env.NODE_ENV === 'production' ? Error : null}>
      <Provider store={store}>
        <BrowserRouter>
          <StyleSheetManager
            disableCSSOMInjection={
              navigator && (navigator.userAgent.includes('Prerender') || navigator.userAgent.includes('prerender'))
            }
          >
            <Root />
          </StyleSheetManager>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  );
}

if (!isOldBrowser && process.env.REACT_APP_TYPE === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
  ReactGA.set({ anonymizeIp: true });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

if (process.env.REACT_APP_TYPE !== 'development') serviceWorker.register();
