import { useEffect, useCallback, useRef } from 'react';

import { useAsyncState } from './useAsyncState';

export const useMobileDetect = (size = 1024) => {
  const [mobile, setMobile] = useAsyncState(window?.innerWidth < size);
  const ref = useRef(mobile);

  const handler = useCallback(() => {
    const current = window?.innerWidth < size;

    if (ref.current === current) return;

    ref.current = current;
    setMobile(current);
  }, [setMobile, size]);

  useEffect(() => {
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [handler]);

  return mobile;
};
