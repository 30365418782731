import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, Button, Icon, Title, Content } from './styles';

const MobileSection = ({ title, children }) => {
  const [isOpened, setOpened] = useState(false);
  const onClick = useCallback(() => setOpened(($) => !$), []);

  return (
    <Container>
      <Button type="button" onClick={onClick} aria-label={title}>
        <Icon $isOpened={isOpened} />
        <Title>{title}</Title>
      </Button>
      <Content $isOpened={isOpened}>{children}</Content>
    </Container>
  );
};

MobileSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default MobileSection;
