import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_PATIENT = getName('UPDATE_PATIENT');
export const RESET_PATIENT = getName('RESET_PATIENT');
export const UPDATE_LICENSES = getName('UPDATE_LICENSES');
export const UPDATE_HEALTH_RECORD = getName('UPDATE_HEALTH_RECORD');
export const UPDATE_ALARMS_FILTERS_BY_TYPE = getName('UPDATE_ALARMS_FILTERS_BY_TYPE');

export const FETCH_PATIENTS = getName('FETCH_PATIENTS');
export const FETCH_PATIENT = getName('FETCH_PATIENT');
export const FETCH_PATIENT_TESTS = getName('FETCH_PATIENT_TESTS');
export const FETCH_PATIENT_INJECTIONS = getName('FETCH_PATIENT_INJECTIONS');
export const CREATE_PATIENT_INJECTION = getName('CREATE_PATIENT_INJECTION');
export const SAVE_PATIENT_INJECTION = getName('SAVE_PATIENT_INJECTION');
export const CREATE_PATIENT = getName('CREATE_PATIENT');
export const SAVE_PATIENT = getName('SAVE_PATIENT');
export const DELETE_PATIENT = getName('DELETE_PATIENT');
export const FETCH_PATIENTS_DASHBOARD = getName('FETCH_PATIENTS_DASHBOARD');
export const CREATE_PATIENT_LABEL = getName('CREATE_PATIENT_LABEL');
export const EXTEND_LICENSE = getName('EXTEND_LICENSE');
export const UPDATE_LICENSE = getName('UPDATE_LICENSE');
export const SAVE_HEALTH_RECORD = getName('SAVE_HEALTH_RECORD');
export const CREATE_PATIENT_REPORT = getName('CREATE_PATIENT_REPORT');
export const VALIDATE_VOUCHER = getName('VALIDATE_VOUCHER');
export const CREATE_INVITE = getName('CREATE_INVITE');
export const CREATE_TESTS_SHEET = getName('CREATE_TESTS_SHEET');
export const CREATE_INJECTIONS_SHEET = getName('CREATE_INJECTIONS_SHEET');
export const FETCH_ALARM_PATIENTS = getName('FETCH_ALARM_PATIENTS');
export const FETCH_ALARM_HISTORY = getName('FETCH_ALARM_HISTORY');
export const FETCH_PATIENT_SURVEY = getName('FETCH_PATIENT_SURVEY');
export const FETCH_ALARMS_BY_TYPE = getName('FETCH_ALARMS_BY_TYPE');
export const FETCH_APPOINTMENT_PATIENTS = getName('FETCH_APPOINTMENT_PATIENTS');
export const CREATE_APPOINTMENT = getName('CREATE_APPOINTMENT');
export const FETCH_PROMS = getName('FETCH_PROMS');
export const FETCH_MEASUREMENTS = getName('FETCH_MEASUREMENTS');
export const SAVE_MEASUREMENT = getName('SAVE_MEASUREMENT');
export const CREATE_MEASUREMENT = getName('CREATE_MEASUREMENT');
