import { combineReducers } from 'redux';

import { FEATURE_NAME as alerts } from './alerts/consts';
import alertsReducer from './alerts/reducer';
import { FEATURE_NAME as app } from './app/consts';
import appReducer from './app/reducer';
import { FEATURE_NAME as user } from './user/consts';
import userReducer from './user/reducer';
import { FEATURE_NAME as organizations } from './organizations/consts';
import organizationsReducer from './organizations/reducer';
import { FEATURE_NAME as patients } from './patients/consts';
import patientsReducer from './patients/reducer';

export default combineReducers({
  [alerts]: alertsReducer,
  [app]: appReducer,
  [user]: userReducer,
  [organizations]: organizationsReducer,
  [patients]: patientsReducer,
});
