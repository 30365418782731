export const getColumns = (isLoggedIn, isUS) => [
  {
    id: 0,
    title: 'Users',
    items: [
      { to: '/download', name: 'Alleye app' },
      { to: '/customer-terms', name: 'Terms and conditions' },
      { to: '/customer-privacy', name: 'App privacy' },
    ],
  },
  {
    id: 1,
    title: 'Healthcare professionals',
    mobileTitle: 'Professionals',
    items: isUS
      ? [
          { to: '/product-alleye', name: 'Technical information' },
          { to: '/research', name: 'Research' },
          { to: isLoggedIn ? '/bugreport' : '/contact', name: 'Report a problem' },
        ]
      : [
          { to: '/become-provider', name: 'Become a provider' },
          { to: '/product-alleye', name: 'Technical information' },
          { to: '/research', name: 'Research' },
          { to: isLoggedIn ? '/bugreport' : '/contact', name: 'Report a problem' },
          { to: isLoggedIn ? '' : '/login', name: isLoggedIn ? 'LogOut' : 'LogIn', isLogout: isLoggedIn },
        ],
  },
  {
    id: 2,
    title: 'About Oculocare',
    items: [
      { to: '/news', name: 'news' },
      { to: '/oculocare', name: 'Leadership' },
      { to: '/imprint', name: 'Imprint' },
      { to: '/contact', name: 'Contact' },
    ],
  },
  {
    id: 3,
    title: 'Resources',
    items: [
      { to: '/alleye-map', name: 'Alleye map' },
      { to: '/accessibility', name: 'Accessibility' },
      { to: '/service-report', name: 'Service report' },
      { href: 'https://storage.googleapis.com/alleye.io/oculocare_iso_13485.pdf', name: 'ISO 13485 certificate' },
      { to: '/privacy', name: 'Privacy' },
    ],
  },
];
