import { useEffect } from 'react';

import { wrapActions, wrapSelector, wrapMultiSelector } from 'store/utils';
import { useUser, useIsLoggedIn, SUB_ORGANIZATION, ID } from 'store/user';
import { useAlerts } from 'store/alerts/hooks';

import * as actions from './actions';
import {
  getOrganization,
  getOrganizationProp,
  getTeam,
  getCard,
  getCardProp,
  getIsAlleye,
  getIsAlleyeOne,
  getIsFromUS,
} from './selectors';

export const useOrganizationsActions = wrapActions({ ...actions });

export const useOrganization = wrapMultiSelector(getOrganizationProp, getOrganization);
export const useTeam = wrapSelector(getTeam);
export const useCard = wrapMultiSelector(getCardProp, getCard);
export const useIsAlleye = wrapSelector(getIsAlleye);
export const useIsAlleyeOne = wrapSelector(getIsAlleyeOne);
export const useIsFromUS = wrapSelector(getIsFromUS);

export const useInitOrganization = () => {
  const isLoggedIn = useIsLoggedIn();
  const userOrganizationId = useUser(SUB_ORGANIZATION);
  const organizationId = useOrganization(ID);
  const { fetchOrganization, resetOrganization } = useOrganizationsActions();
  const { id, extractId, loading, success, error, resetAlerts } = useAlerts();

  useEffect(() => {
    if (!id && isLoggedIn && userOrganizationId) {
      extractId(fetchOrganization());
    }
    if (id && !(isLoggedIn && userOrganizationId)) {
      resetOrganization();
      resetAlerts();
    }
  }, [extractId, fetchOrganization, id, isLoggedIn, organizationId, resetAlerts, resetOrganization, userOrganizationId]);

  return isLoggedIn && userOrganizationId ? { error, success, loading: !(success || error) || loading } : { success: true };
};
