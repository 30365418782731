import React, { lazy } from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import GlobalStyles from 'theme/GlobalStyles';

import { ROUTES } from './utils';
import Helmet from './Helmet';
import Layout from './Layout';
import SuspenseRoute from './SuspenseRoute';

const Home = lazy(() => import('pages/Home'));

const Root = () => (
  <>
    <Helmet />
    <GlobalStyles />
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<SuspenseRoute component={Home} />} />
        {ROUTES.map(({ path, isPrivate, component }) => (
          <Route key={path} path={path} element={<SuspenseRoute component={component} isPrivate={isPrivate} />} />
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  </>
);

export default Root;
