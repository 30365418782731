import { any, equals } from 'ramda';

import i18n from 'locale';
import { APP_LANGS } from 'locale/consts';

const getFontFamily = (bold) => {
  if (any(equals(i18n.language), APP_LANGS)) {
    return `NimbusSanL-${bold ? 'Bold' : 'Regu'}`;
  }

  return `NotoSans-${bold ? 'Bold' : 'Regular'}`;
};

const defFont = `
  font-weight: normal;
  line-height: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #044e33;
`;

export default {
  h1: () => `
    font-family: ${getFontFamily()};
    font-size: 48px;
    ${defFont}
  `,
  p: ({ $bold }) => `
    font-family: ${getFontFamily($bold)};
    font-size: 25px;
    ${defFont}
    font-weight: ${$bold ? 'bold' : 'normal'};
    line-height: 1.24;
  `,
  small: ({ $bold }) => `
    font-family: ${getFontFamily($bold)};
    font-size: 20px;
    ${defFont}
    font-weight: ${$bold ? 'bold' : 'normal'};
  `,
  button: () => `
    font-family: ${getFontFamily()};
    font-size: 32px;
    ${defFont}
  `,
};
