import styled from 'styled-components';
import { motion } from 'framer-motion';

import ArrowLink from 'components/ArrowLink';

export const Container = styled(motion.nav).attrs(({ $isOpened }) => ({
  animate: { x: $isOpened ? '0' : '100%', borderRadius: $isOpened ? '0% 0% 0% 0%' : '0% 0% 0% 100%' },
  transition: {
    x: { duration: $isOpened ? 0.15 : 0.05 },
    borderRadius: { delay: $isOpened ? 0.1 : 0.15, duration: $isOpened ? 0.1 : 0 },
  },
  initial: false,
}))`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  inset: 0;
  z-index: 10;
  border-radius: 0% 0% 0% 0%;
  background-color: rgba(var(--color-white-rpg), 0.7);
`;

export const Wrapper = styled(motion.div).attrs(({ $isOpened }) => ({
  animate: { x: $isOpened ? '0' : '100%', borderRadius: $isOpened ? '0% 0% 0% 0%' : '0% 0% 0% 100%' },
  transition: { x: { duration: 0.25 }, borderRadius: { delay: $isOpened ? 0.15 : 0.25, duration: $isOpened ? 0.15 : 0 } },
  initial: false,
}))`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  border-radius: 0% 0% 0% 0%;
  background-color: var(--color-grey-blue);
  overflow: auto;
  padding: 28px 0 20px 25px;
  @media (max-width: 389px) {
    padding: 20px 0 20px 10px;
  }
`;

export const Items = styled(motion.div).attrs(({ $isOpened }) => ({
  animate: $isOpened ? 'visible' : 'hidden',
  variants: {
    visible: {
      opacity: 1,
      transition: { delayChildren: 0.1, staggerChildren: 0.05, duration: 0 },
    },
    hidden: {
      opacity: 1,
      transition: { duration: 0 },
    },
  },
  initial: 'hidden',
}))`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 70px;
  @media (max-width: 991px) {
    gap: 25px;
  }
`;

export const LinkWrap = styled.div`
  width: 100%;
  min-height: 40px;
  padding-right: 25px;
  overflow: hidden;
  @media (max-width: 389px) {
    padding-right: 10px;
  }
  @media (min-width: 992px) {
    display: ${({ $onlyMobile }) => ($onlyMobile ? 'none' : 'block')};
  }
`;

export const Link = styled(motion(ArrowLink)).attrs(() => ({
  variants: {
    visible: {
      x: '0%',
      opacity: 1,
      transition: { duration: 0.25 },
    },
    hidden: {
      x: '100%',
      opacity: 0,
      transition: { duration: 0.25 },
    },
  },
}))`
  width: 100%;
`;
