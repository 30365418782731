import { takeLatest, call, put, throttle, takeEvery, select, all } from 'redux-saga/effects';
import { prop, pick, pipe, omit, props, pathOr } from 'ramda';

import api from 'api';
import { withAlert, applyCancelToken } from 'store/alerts';
import { normalizeArr } from 'store/utils';

import { ID, COUNT, HAS_MORE, SUB_LICENSE, EXPAND, SUB_USER, PATIENT_ID, CODE, AUTO_RENEW, LIMIT, VA, CRT, EYE } from '.';
import { updatePatient, updateAlarmsFiltersByType, updateLicenses, updateHealthRecord } from './actions';
import { getPatientProp, getHealthRecord } from './selectors';
import {
  FETCH_PATIENTS,
  FETCH_PATIENT,
  FETCH_PATIENT_TESTS,
  FETCH_PATIENT_INJECTIONS,
  CREATE_PATIENT_INJECTION,
  SAVE_PATIENT_INJECTION,
  CREATE_PATIENT,
  SAVE_PATIENT,
  DELETE_PATIENT,
  FETCH_PATIENTS_DASHBOARD,
  CREATE_PATIENT_LABEL,
  EXTEND_LICENSE,
  UPDATE_LICENSE,
  SAVE_HEALTH_RECORD,
  CREATE_PATIENT_REPORT,
  VALIDATE_VOUCHER,
  CREATE_INVITE,
  CREATE_TESTS_SHEET,
  CREATE_INJECTIONS_SHEET,
  FETCH_ALARM_PATIENTS,
  FETCH_ALARM_HISTORY,
  FETCH_PATIENT_SURVEY,
  FETCH_ALARMS_BY_TYPE,
  FETCH_APPOINTMENT_PATIENTS,
  CREATE_APPOINTMENT,
  FETCH_PROMS,
  FETCH_MEASUREMENTS,
  SAVE_MEASUREMENT,
  CREATE_MEASUREMENT,
} from './types';
import { getAlarmOptions } from './utils';

function* fetchPatients({ payload }) {
  const data = yield call(api.get, '/patients', { params: payload });

  if (data?.error) return { error: data.error };

  return { success: data?.data };
}

function* fetchAlarmPatients({ payload }) {
  const data = yield call(api.get, '/alarms', { params: payload });

  if (data?.error) return { error: data.error };

  return {
    success: {
      list: data?.data && data.data.map((item) => ({ ...item, ...item[SUB_USER] })),
      count: data && data[COUNT],
    },
  };
}

function* fetchAlarmsByType({ payload }) {
  const { url, params, extractData } = getAlarmOptions(payload.type);
  const data = yield call(api.get, url, { params: { ...omit(['type'], payload), ...params } });

  if (data?.error) return { error: data.error };

  yield put(updateAlarmsFiltersByType({ type: payload.type || 'default', ...pick([COUNT, HAS_MORE], data) }));

  return { success: data?.data ? extractData(data.data) : [] };
}

function* fetchAlarmHistory({ payload }) {
  const id = yield select(getPatientProp(ID));

  return { success: yield call(api.get, `/patients/${id}/alarms`, { params: payload }) };
}

function* fetchPatient({ payload, ...rest }) {
  const [patient, licenses, healthRecords] = yield all([
    call(api.get, `/patients/${payload}`, { params: { [EXPAND]: [SUB_LICENSE] }, ...applyCancelToken(rest) }),
    call(api.get, '/licenses', { params: { [PATIENT_ID]: payload }, ...applyCancelToken(rest) }),
    call(api.get, `/patients/${payload}/healthrecords`, { params: { [LIMIT]: 1 }, ...applyCancelToken(rest) }),
  ]);

  yield put(updatePatient(patient));
  yield put(updateLicenses(licenses));
  yield put(updateHealthRecord(pathOr({}, ['data', 0], healthRecords)));
}

function* fetchPatientTests({ payload }) {
  const id = yield select(getPatientProp(ID));

  return { success: yield call(api.get, '/tests', { params: { [SUB_USER]: id, ...payload } }) };
}

function* fetchPatientInjections({ payload }) {
  const id = yield select(getPatientProp(ID));
  return { success: yield call(api.get, `/patients/${id}/injections`, { params: payload }) };
}

function* createPatientInjection({ payload }) {
  const id = yield select(getPatientProp(ID));

  return {
    success: yield all(payload[EYE].map((eye) => call(api.post, `/patients/${id}/injections`, { ...payload, [EYE]: eye }))),
  };
}

function* savePatientInjection({ payload, ...rest }) {
  return { success: yield call(api.patch, `/injections/${payload[ID]}`, omit([ID], payload), applyCancelToken(rest)) };
}

function* createPatient({ payload }) {
  const data = yield call(api.post, '/patients/', payload, { params: { [EXPAND]: [SUB_LICENSE] } });

  if (data?.error) return { error: data.error };

  const licenses = yield call(api.get, '/licenses', { params: { [PATIENT_ID]: data[ID] } });

  yield put(updatePatient(data));
  yield put(updateLicenses(licenses));

  return { success: data };
}

function* savePatient({ payload }) {
  const id = yield select(getPatientProp(ID));
  const data = yield call(api.patch, `/patients/${id}`, payload, { params: { [EXPAND]: [SUB_LICENSE] } });

  if (data?.error) return { error: data.error };

  yield put(updatePatient(data));

  return { success: 'Patient successfully updated' };
}

function* deletePatient() {
  const id = yield select(getPatientProp(ID));
  yield call(api.delete, `/patients/${id}`);
}

function* fetchPatientDashboard(action) {
  return { success: yield call(api.get, '/patients/dashboard', applyCancelToken(action)) };
}

function* createPatientLabel({ payload, ...rest }) {
  return { success: yield call(api.post, `/patients/${payload}/label`, {}, applyCancelToken(rest)) };
}

function* extendLicense({ payload, ...rest }) {
  const [id, autoRenew] = yield select(pipe(getPatientProp(SUB_LICENSE), props([ID, AUTO_RENEW])));

  return { success: yield call(api.put, `/licenses/${id}`, { ...payload, [AUTO_RENEW]: autoRenew }, applyCancelToken(rest)) };
}

function* updateLicense({ payload, ...rest }) {
  const id = yield select(pipe(getPatientProp(SUB_LICENSE), prop(ID)));
  const data = yield call(api.patch, `/licenses/${id}`, payload, applyCancelToken(rest));

  yield put(updatePatient({ [SUB_LICENSE]: data }));
}

function* saveHealthRecord({ payload, ...rest }) {
  const { [ID]: id } = yield select(getHealthRecord);
  const healthRecord = yield call(api.patch, `/healthrecords/${id}`, payload, applyCancelToken(rest));

  yield put(updateHealthRecord(healthRecord));
}

function* createPatientReport({ payload, ...rest }) {
  const id = yield select(getPatientProp(ID));
  const data = yield call(api.post, `/patients/${id}/report`, {}, { params: payload, ...applyCancelToken(rest) });

  if (data?.error) return { error: data.error };

  return { success: data };
}

function* validateVoucher({ payload, ...rest }) {
  return { success: yield call(api.get, '/voucher', { params: { [CODE]: payload }, ...applyCancelToken(rest) }) };
}

function* createInvite() {
  const id = yield select(getPatientProp(ID));
  return yield call(api.post, `/patients/${id}/invite`, {});
}

function* createTestsSheet(action) {
  const id = yield select(getPatientProp(ID));
  const data = yield call(api.downloadFile, `/patients/${id}/alleyeexport`, {}, applyCancelToken(action));

  if (data?.error) return { error: data.error };

  return { success: data };
}

function* createInjectionsSheet(action) {
  const id = yield select(getPatientProp(ID));
  const data = yield call(api.downloadFile, `/patients/${id}/injectionexport`, {}, applyCancelToken(action));

  if (data?.error) return { error: data.error };

  return { success: data };
}

function* fetchPatientSurvey({ payload }) {
  const id = yield select(getPatientProp(ID));

  return { success: yield call(api.get, `/patients/${id}/surveys`, { params: payload }) };
}

function* fetchAppointmentPatients({ payload }) {
  const data = yield call(api.get, '/patients', { params: payload });

  if (data?.error) return { error: data.error };

  return { success: data };
}

function* createAppointment({ payload, ...rest }) {
  return { success: yield call(api.post, `/patients/${payload[ID]}/appointments`, omit([ID], payload), applyCancelToken(rest)) };
}

function* fetchProms({ payload }) {
  const id = yield select(getPatientProp(ID));

  return { success: yield call(api.get, `/patients/${id}/proms`, { params: payload }) };
}

function* fetchMeasurements({ payload }) {
  const id = yield select(getPatientProp(ID));
  const [vas, crts] = yield all([
    yield call(api.get, `/patients/${id}/vas`, { params: payload }),
    yield call(api.get, `/patients/${id}/crts`, { params: payload }),
  ]);

  return { success: { vas: normalizeArr(ID, vas.data), crts: normalizeArr(ID, crts.data) } };
}

function* saveMeasurement({ payload, ...rest }) {
  const feature = (payload.type === VA && 'vas') || (payload.type === CRT && 'crts') || null;

  if (!feature) throw Error('Incorrect request');

  return { success: yield call(api.patch, `/${feature}/${payload[ID]}`, omit(['type', ID], payload), applyCancelToken(rest)) };
}

function* createMeasurement({ payload }) {
  const id = yield select(getPatientProp(ID));
  const feature = (payload.type === VA && 'vas') || (payload.type === CRT && 'crts') || null;

  if (!feature) throw Error('Incorrect request');

  return { success: yield call(api.post, `/patients/${id}/${feature}`, omit(['type'], payload)) };
}

export default function* watchUser() {
  yield takeEvery(FETCH_PATIENTS, withAlert(fetchPatients));
  yield takeEvery(FETCH_ALARM_PATIENTS, withAlert(fetchAlarmPatients));
  yield throttle(500, FETCH_ALARMS_BY_TYPE, withAlert(fetchAlarmsByType));
  yield takeEvery(FETCH_ALARM_HISTORY, withAlert(fetchAlarmHistory));
  yield takeLatest(FETCH_PATIENT, withAlert(fetchPatient));
  yield takeEvery(FETCH_PATIENT_TESTS, withAlert(fetchPatientTests));
  yield takeEvery(FETCH_PATIENT_INJECTIONS, withAlert(fetchPatientInjections));
  yield takeEvery(CREATE_PATIENT_INJECTION, withAlert(createPatientInjection));
  yield takeLatest(SAVE_PATIENT_INJECTION, withAlert(savePatientInjection));
  yield takeEvery(CREATE_PATIENT, withAlert(createPatient));
  yield takeEvery(SAVE_PATIENT, withAlert(savePatient));
  yield takeEvery(DELETE_PATIENT, withAlert(deletePatient));
  yield takeLatest(FETCH_PATIENTS_DASHBOARD, withAlert(fetchPatientDashboard));
  yield takeLatest(CREATE_PATIENT_LABEL, withAlert(createPatientLabel));
  yield takeLatest(EXTEND_LICENSE, withAlert(extendLicense));
  yield takeLatest(UPDATE_LICENSE, withAlert(updateLicense));
  yield takeLatest(SAVE_HEALTH_RECORD, withAlert(saveHealthRecord));
  yield takeLatest(CREATE_PATIENT_REPORT, withAlert(createPatientReport));
  yield takeLatest(VALIDATE_VOUCHER, withAlert(validateVoucher));
  yield takeEvery(CREATE_INVITE, withAlert(createInvite));
  yield takeLatest(CREATE_TESTS_SHEET, withAlert(createTestsSheet));
  yield takeLatest(CREATE_INJECTIONS_SHEET, withAlert(createInjectionsSheet));
  yield throttle(500, FETCH_PATIENT_SURVEY, withAlert(fetchPatientSurvey));
  yield throttle(500, FETCH_APPOINTMENT_PATIENTS, withAlert(fetchAppointmentPatients));
  yield takeLatest(CREATE_APPOINTMENT, withAlert(createAppointment));
  yield takeEvery(FETCH_PROMS, withAlert(fetchProms));
  yield takeEvery(FETCH_MEASUREMENTS, withAlert(fetchMeasurements));
  yield takeLatest(SAVE_MEASUREMENT, withAlert(saveMeasurement));
  yield takeEvery(CREATE_MEASUREMENT, withAlert(createMeasurement));
}
