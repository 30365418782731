import styled from 'styled-components';
import { motion } from 'framer-motion';

export const PreImage = styled(motion.img).attrs(() => ({
  animate: { opacity: 1 },
  transition: { duration: 0.1 },
  initial: { opacity: 0 },
}))`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  will-change: opacity;
  filter: blur(30px);
`;

export const Picture = styled.picture`
  display: ${({ $isLoaded }) => ($isLoaded ? 'block' : 'none')};
`;

export const Image = styled(motion.img).attrs(({ $isLoaded }) => ({
  animate: { filter: $isLoaded ? 'blur(0px)' : 'blur(30px)' },
  transition: { duration: 1 },
  initial: { filter: 'blur(30px)' },
}))`
  width: 100%;
  height: 100%;
  object-fit: cover;
  will-change: filter;
`;
