import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useMatch } from 'react-router-dom';

import { useIsLoggedIn } from 'store/user';
import { useIsFromUS } from 'store/organizations';

import LangSwitcher from './LangSwitcher';
import Menu from './Menu';
import { Container, HomeLink, Button, Link, EyeIcon, MenuIcon } from './styles';

const ExternalHeader = () => {
  const { t } = useTranslation('app');
  const { pathname } = useLocation();
  const [isOpened, setOpened] = useState(false);
  const isLoggedIn = useIsLoggedIn();
  const isFromUS = useIsFromUS();
  const isAppExplained = Boolean(useMatch('/app-explained'));

  const openMenu = useCallback(() => setOpened(true), []);
  const closeMenu = useCallback(() => setOpened(false), []);

  useEffect(() => {
    closeMenu();
  }, [closeMenu, pathname]);

  return (
    <Container>
      <HomeLink
        to={isLoggedIn ? '/dashboard' : '/'}
        aria-label={t(isLoggedIn ? 'Dashboard page' : 'Main page')}
        title={t(isLoggedIn ? 'Dashboard page' : 'Main page')}
      >
        <EyeIcon />
      </HomeLink>
      {isLoggedIn || isAppExplained ? (
        <div />
      ) : (
        <Link to="/provider" $onlyDesktop>
          {t('For healthcare professionals')}
        </Link>
      )}
      {isAppExplained || (isFromUS && !isLoggedIn) ? (
        <div />
      ) : (
        <Link to={isLoggedIn ? '/dashboard' : '/login'}>{t(isLoggedIn ? 'Dashboard' : 'LogIn')}</Link>
      )}
      <Button type="button" onClick={openMenu} aria-label={t('Menu')} title={t('Menu')}>
        <MenuIcon />
      </Button>
      <LangSwitcher />
      <Menu isOpened={isOpened} closeMenu={closeMenu} />
    </Container>
  );
};

export default ExternalHeader;
