import axiosDef from 'axios';
import { omit, T } from 'ramda';

import { URL, API_URL, APPLICATION_TOKEN, TOKEN, TOKEN_2FA, DEFAULT_ERROR } from './consts';

export { URL, API_URL, APPLICATION_TOKEN, TOKEN, TOKEN_2FA };

const getAuthToken = () => {
  const sessionToken = localStorage[TOKEN_2FA] || localStorage[TOKEN];

  return sessionToken ? `Bearer ${sessionToken}` : APPLICATION_TOKEN;
};

export const axios = axiosDef.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'App-Token': APPLICATION_TOKEN,
    AUTHORIZATION: getAuthToken(),
  },
  timeout: 60000,
  validateStatus: T,
});

export const updateToken = (token, token2FA) => {
  if (token) {
    localStorage.setItem(TOKEN, token);
    localStorage.removeItem(TOKEN_2FA);
    axios.defaults.headers = {
      ...axios.defaults.headers,
      AUTHORIZATION: `Bearer ${token}`,
    };
  }
  if (token2FA) {
    localStorage.setItem(TOKEN_2FA, token2FA);
    localStorage.removeItem(TOKEN);
    axios.defaults.headers = {
      ...axios.defaults.headers,
      AUTHORIZATION: `Bearer ${token2FA}`,
    };
  }
  if (!(token || token2FA)) {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(TOKEN_2FA);
    axios.defaults.headers = omit(['AUTHORIZATION'], axios.defaults.headers);
  }
};

const handleResponse = ({ status, data }) => {
  if (status === 401) {
    updateToken();
    window.location.reload();
  }

  if (status === 413) {
    const error = new Error('File Upload Failed');
    error.status = status;
    throw error;
  }

  if (status === 415) {
    const error = new Error('File Formats');
    error.status = status;
    throw error;
  }

  if (status === 429) {
    const error = new Error('Already Sent Reset Link');
    error.status = status;
    throw error;
  }

  if (status >= 300) {
    const error = new Error((status === 401 && 'Authentication Failed') || (status === 403 && data?.error) || DEFAULT_ERROR);
    error.status = status;
    throw error;
  }

  return data;
};

const handler = async (promise, ...args) => {
  try {
    return handleResponse(await promise(...args));
  } catch (e) {
    if (e?.status) throw Error(e?.message);

    throw Error('Failed to fetch');
  }
};

const methods = {
  get: (...args) => handler(axios.get, ...args),
  post: (...args) => handler(axios.post, ...args),
  put: (...args) => handler(axios.put, ...args),
  patch: (...args) => handler(axios.patch, ...args),
  delete: (...args) => handler(axios.delete, ...args),
  uploadFile: (url, data, params) =>
    handler(axios.post, url, data, {
      ...params,
      headers: { 'Content-Type': 'multipart/form-data', AUTHORIZATION: getAuthToken() },
    }),
  downloadFile: (url, data, params) => handler(axios.post, url, data, { ...params, responseType: 'blob' }),
};

export default methods;
