import styled from 'styled-components';
import { motion } from 'framer-motion';

import { APP_LANGS } from 'locale/consts';

export const Container = styled.div`
  grid-column: 1/-1;
  justify-self: end;
  display: grid;
  grid-template-columns: 26px 26px 37px;
  grid-template-areas: '${({ $current }) => [...APP_LANGS.filter((lng) => lng !== $current), $current].join(' ')}';
  justify-items: end;
  gap: 0 8px;
  height: 48px;
  margin-right: 8px;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const Button = styled(motion.button).attrs(({ $isVisible, disabled }) => ({
  animate: {
    opacity: $isVisible || disabled ? 1 : 0,
    visibility: $isVisible || disabled ? 'visible' : 'hidden',
  },
  transition: {
    opacity: { duration: 0.5 },
    visibility: { delay: $isVisible || disabled ? 0 : 0.5 },
  },
  initial: false,
}))`
  grid-row: 1/2;
  grid-area: ${({ value }) => value};
  width: 26px;
  height: 48px;
  border: 1px solid var(--color-black);
  border-radius: 13px;
  background-color: rgba(var(--color-white-rpg), 0.5);
  color: var(--color-black);
  opacity: 0;
  visibility: hidden;
  &:disabled {
    background-color: var(--color-black);
    color: var(--color-white);
    visibility: visible;
    opacity: 1;
    cursor: w-resize;
  }
`;

export const Text = styled.p`
  font: var(--font20);
  letter-spacing: -3px;
  writing-mode: vertical-rl;
  text-orientation: upright;
  text-transform: uppercase;
  text-align: center;
  color: inherit;
  margin-bottom: 3px;
`;
