import { handleActions } from 'redux-actions';

import { ORGANIZATION, TEAM, CARD } from './consts';
import { UPDATE_ORGANIZATION, UPDATE_TEAM, RESET_ORGANIZATION, UPDATE_CARD_DATA } from './types';

const initState = {
  [ORGANIZATION]: {},
  [TEAM]: [],
};

export default handleActions(
  {
    [UPDATE_ORGANIZATION]: (state, { payload }) => ({
      ...state,
      [ORGANIZATION]: payload,
    }),
    [UPDATE_CARD_DATA]: (state, { payload }) => ({
      ...state,
      [ORGANIZATION]: {
        ...state[ORGANIZATION],
        [CARD]: payload,
      },
    }),
    [UPDATE_TEAM]: (state, { payload }) => ({
      ...state,
      [TEAM]: payload,
    }),
    [RESET_ORGANIZATION]: () => initState,
  },
  initState
);
