import { Children } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  align-self: end;
  display: grid;
  grid-template-columns: ${({ children }) => `repeat(${Children.count(children)}, 42px)`};
  gap: 0 8px;
  height: 42px;
  margin-right: 25px;
  @media (max-width: 389px) {
    margin-right: 10px;
  }
  @media (min-width: 992px) {
    display: none;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-color: var(--color-black);
  border-radius: 50%;
  background-color: var(--color-white);
  font: var(--font20);
  color: var(--color-black);
  text-transform: uppercase;
  &:disabled {
    border-color: var(--color-white);
    background-color: var(--color-black);
    color: var(--color-white);
  }
`;
