import { useCallback, useEffect, useRef } from 'react';
import { pipe, path } from 'ramda';

import { useAsyncState } from 'hooks';
import { wrapActions, wrapSelectorWithArg } from 'store/utils';

import { META, TASK_ID } from './consts';
import * as actions from './actions';
import { getSuccess, getError, getLoading } from './selectors';

export const useAlertsActions = wrapActions({ ...actions });

export const useSuccess = wrapSelectorWithArg(getSuccess);
export const useError = wrapSelectorWithArg(getError);
export const useLoading = wrapSelectorWithArg(getLoading);

export const useAlerts = (actionCreator, actionAfter) => {
  const timer = useRef();
  const index = useRef();
  const completedAction = useRef();
  const [id, setId] = useAsyncState();

  const success = useSuccess(id);
  const error = useError(id);
  const loading = useLoading(id);
  const { setCancel } = useAlertsActions();

  const extractId = useCallback(
    (action, onCompleted) => {
      pipe(path([META, TASK_ID]), (value) => {
        index.current = value;
        setId(value);
      })(action);
      completedAction.current = onCompleted;
    },
    [setId]
  );

  const resetAlerts = useCallback(() => {
    if (!index.current) return;
    setId();
    setCancel(index.current);
    index.current = null;
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, [setCancel, setId]);

  const resetAlertsWithDelay = useCallback(
    (delay) => {
      timer.current = setTimeout(() => {
        resetAlerts();
        timer.current = null;
      }, delay);
    },
    [resetAlerts]
  );

  const handleAction = useCallback(
    (...args) => extractId(actionCreator(...args), actionAfter),
    [actionAfter, actionCreator, extractId]
  );

  useEffect(() => {
    if (success && typeof completedAction.current === 'function') {
      completedAction.current(success);
      completedAction.current = null;
    }
  }, [success]);

  useEffect(
    () => () => {
      if (index.current) setCancel(index.current);
      if (timer.current) clearTimeout(timer.current);
    },
    [setCancel]
  );

  return {
    id,
    extractId,
    success: id && success,
    error: id && error,
    loading: id && loading,
    resetAlerts,
    resetAlertsWithDelay,
    action: handleAction,
  };
};
