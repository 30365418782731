import styled from 'styled-components';

export const CenteredContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 90%;
  max-width: 990px;
  min-height: calc(100vh - 250px);
`;

const font = `
  font-family: 'NimbusSanL-Regu', Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: 0.5px;
  text-align: center;
  color: #6a6a6a;
  margin-bottom: 2em;
  @media (max-width: 992px) {
    font-size: 17px;
  }
`;

export const Title = styled.h1`
  ${font}
  font-size: 60px;
  line-height: 0.5;
  letter-spacing: 1px;
  margin-bottom: 1.5em;
  @media (max-width: 992px) {
    font-size: 34px;
  }
`;

export const Text = styled.p`
  ${font}
`;

export const Restart = styled.button`
  ${font}
  font-weight: bold;
  border: none;
  background: none;
  outline: none;
  text-decoration: underline;
  cursor: pointer;
  &:disabled {
    text-decoration: none;
    cursor: auto;
  }
`;
