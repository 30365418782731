import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px auto;
`;

export const Loader = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 3px solid rgba(167, 229, 201, 0.2);
  border-right: 3px solid rgba(167, 229, 201, 0.2);
  border-bottom: 3px solid rgba(167, 229, 201, 0.2);
  border-left: 3px solid #a7e5c9;
  animation: spin 1.3s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
