import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { propOr } from 'ramda';

import { ID, TOKEN_2FA, TOKEN } from 'api/consts';
import { wrapActions, wrapSelector, wrapMultiSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';

import { AVAILABLE } from './consts';
import * as actions from './actions';
import { getFullName, getUserProp, getUser, getIsAdmin, getIsLoggedIn } from './selectors';

export const useUserActions = wrapActions({ ...actions });

export const useUser = wrapMultiSelector(getUserProp, getUser);
export const useFullName = wrapSelector(getFullName);
export const useIsAdmin = wrapSelector(getIsAdmin);
export const useIsLoggedIn = wrapSelector(getIsLoggedIn);

export const useSession = () => {
  const navigate = useNavigate();
  const { restore, logout } = useUserActions();
  const userId = useUser(ID);
  const hasToken = Boolean(localStorage[TOKEN_2FA] || localStorage[TOKEN]);

  const { id, extractId, success, loading, error, resetAlerts } = useAlerts();

  useEffect(() => {
    if (!id && !userId && hasToken) extractId(restore());
    if (error) {
      logout();
      navigate('/login');
      resetAlerts();
    }
  }, [error, extractId, hasToken, id, logout, navigate, resetAlerts, restore, userId]);

  return hasToken ? { success, loading: !(id || userId) || loading, error } : { success: true };
};

export const useStatistics = () => {
  const { fetchStatistics } = useUserActions();
  const { id, extractId, loading, success, error } = useAlerts();
  const available = useMemo(() => propOr(0, AVAILABLE, success || {}), [success]);

  useEffect(() => {
    if (!id) extractId(fetchStatistics());
  }, [extractId, fetchStatistics, id]);

  return { amount: available, loading: !id || loading, error };
};
