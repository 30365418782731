import { takeLatest, call, takeEvery, select, takeLeading, put } from 'redux-saga/effects';
// import axios from 'axios';

import api from 'api';
import { withAlert, applyCancelToken } from 'store/alerts';
import { getUserProp } from 'store/user/selectors';

import { UPLOAD_FILE, CREATE_BUG_REPORT, CREATE_ABROAD_BUG_REPORT, FETCH_COUNTRIES, DEFINE_BROWSER_COUNTRY } from './types';
import { setBrowserCountry } from './actions';
import { checkBrowserUS } from './utils';
import { ID, SUB_SENDER } from '.';

function* uploadFile({ payload }) {
  const body = new FormData();
  body.append('file', payload);

  return { success: yield call(api.uploadFile, '/uploads', body) };
}

function* createBugReport({ payload, ...rest }) {
  const id = yield select(getUserProp(ID));

  yield call(
    api.post,
    '/bugreports',
    {
      ...(id && { [SUB_SENDER]: id }),
      ...payload,
    },
    applyCancelToken(rest)
  );
}

function* createAbroadBugReport({ payload, ...rest }) {
  yield call(api.post, '/bugreports', payload, applyCancelToken(rest));

  return { success: 'Your message has been sent successfully.' };
}

function* fetchCountries(action) {
  return { success: yield call(api.get, '/alleyecountries', applyCancelToken(action)) };
}

function* defineBrowserCountry() {
  // try {
  //   const { data } = yield call(axios.get, 'http://ip-api.com/json/?fields=16387');

  //   if (data.status !== 'success' || !data.countryCode) throw Error('request is fail');

  //   yield put(setBrowserCountry(data.countryCode.toUpperCase()));
  if (checkBrowserUS()) yield put(setBrowserCountry('US'));
  // } catch (error) {
  //   if (checkBrowserUS()) yield put(setBrowserCountry('US'));
  //   if (process.env.NODE_ENV === 'development') console.error('defineBrowserCountry: ', error); // eslint-disable-line no-console
  // }
}

export default function* watchApp() {
  yield takeEvery(UPLOAD_FILE, withAlert(uploadFile));
  yield takeLatest(CREATE_BUG_REPORT, withAlert(createBugReport));
  yield takeLatest(CREATE_ABROAD_BUG_REPORT, withAlert(createAbroadBugReport));
  yield takeLatest(FETCH_COUNTRIES, withAlert(fetchCountries));
  yield takeLeading(DEFINE_BROWSER_COUNTRY, withAlert(defineBrowserCountry));
}
