import VisionSaveLogo from 'assets/svg-icons/VisionsaveLogo';
import { EN, DE, ZH, CH, AR, PL, GB, SG, UAE, DEU, POL, US } from 'locale/consts';

export const PATHS = {
  AGREEMENTS: 'agreements',
  REGISTER: 'register',
  PHONE: 'phone',
  INSTRUCTIONS: 'instructions',
  DOWNLOAD: 'download',
  THANKS: 'thanks',
  IMPRINT: 'imprint',
};

const singaporeHospitals = [
  'SNEC',
  'TTSH',
  'NUH. NTFGH',
  'KTPH',
  'CGH',
  'SKH',
  'Mt E Orchard',
  'Mt E Novena',
  'Glen. Farrer park',
  'Raffles hospital',
  'Alexandra Hosp',
  'Mount Alvernia',
  'Other Public clinic',
  'Other private clinic',
].map((id) => ({ id, label: id }));

export const COUNTRY_SETTINGS = {
  '/visionsave': {
    route: '/visionsave',
    countries: [SG],
    languages: [EN, ZH],
    languageSelect: true,
    logo: VisionSaveLogo,
    fields: {
      firstName: true,
      lastName: true,
      phone: true,
      hospital: true,
      termsAccepted: true,
      termsUseAccepted: true,
      homeMonitoringConsent: true,
      researchConsent: true,
    },
    phone: {
      [SG]: {
        prefix: 65,
        placeholder: 'XXXX XXXX',
        regExp: /^\d{8}$/,
      },
    },
    hospitalList: singaporeHospitals,
    extraAgreement: '$singaporeExtraInfo',
  },
  '/mobile': {
    route: '/mobile',
    countries: [GB, CH, US, UAE, DEU, POL],
    languages: [EN, DE, AR, PL],
    languageSelect: false,
    logo: null,
    fields: {
      firstName: true,
      lastName: true,
      phone: true,
      hospital: false,
      termsAccepted: true,
      termsUseAccepted: true,
      homeMonitoringConsent: true,
      researchConsent: true,
    },
    phone: {
      [GB]: {
        prefix: 44,
        placeholder: '7XXX XXXXXX',
        regExp: /^7\d{9}$/,
      },
      [US]: {
        prefix: 1,
        placeholder: 'XXX XXX XXXX',
        regExp: /^\d{10}$/,
      },
      [CH]: {
        prefix: 41,
        placeholder: '7X XXX XX XX',
        regExp: /^7\d{8}$/,
      },
      [UAE]: {
        prefix: 971,
        placeholder: '5X XXX XXXX',
        regExp: /^5\d{8}$/,
      },
      [DEU]: {
        prefix: 49,
        placeholder: 'XXXXX XXXXXX',
        regExp: /^\d{10,11}$/,
      },
      [POL]: {
        prefix: 48,
        placeholder: 'XXX XXX XXX',
        regExp: /^\d{9}$/,
      },
    },
  },
};
