import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_USER = getName('UPDATE_USER');
export const LOGOUT = getName('LOGOUT');

export const FETCH_LICENSE_BY_VOUCHER = getName('FETCH_LICENSE_BY_VOUCHER');
export const FETCH_STATISTICS = getName('FETCH_STATISTICS');
export const RESTORE = getName('RESTORE');
export const LOGIN = getName('LOGIN');
export const REGISTER_USER = getName('REGISTER_USER');
export const REGISTER_ABROAD_USER = getName('REGISTER_ABROAD_USER');
export const SAVE_USER = getName('SAVE_USER');
export const DELETE_USER = getName('DELETE_USER');
export const UPDATE_PASSWORD = getName('UPDATE_PASSWORD');
export const RESET_PASSWORD = getName('RESET_PASSWORD');
export const SAVE_PASSWORD = getName('SAVE_PASSWORD');
export const CREATE_2FA = getName('CREATE_2FA');
export const VALIDATE_2FA = getName('VALIDATE_2FA');
export const DELETE_2FA = getName('DELETE_2FA');
export const AUTH_2FA = getName('AUTH_2FA');
