import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { ReactComponent as SmallEye } from 'assets/svg-icons/smallEye.svg';
import { ReactComponent as Menu } from 'assets/svg-icons/menu.svg';

export const Container = styled.header`
  display: grid;
  grid-template-columns: 1fr repeat(3, auto);
  gap: 10px;
  width: 100%;
  height: var(--header-height);
  position: fixed;
  inset: 0 0 auto 0;
  padding: 20px 25px;
  z-index: 10;
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }
  @media (max-width: 991px) {
    grid-template-columns: 1fr repeat(3, auto);
    padding: 15px;
  }
  @media (max-width: 369px) {
    gap: 8px;
  }
`;

const iconWrapStyle = `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border: 1px solid;
  border-color: var(--color-black);
  border-radius: 21px;
  background-color: var(--color-black);
  color: var(--color-white);
  @media (hover) {
    &:hover {
      border-color: var(--color-green-active);
      background-color: var(--color-white);
      color: var(--color-green-active);
    }
  }
  &:active {
    border-color: var(--color-green-active);
    background-color: var(--color-green-active);
    color: var(--color-black);
  }
`;

export const HomeLink = styled(NavLink)`
  ${iconWrapStyle}
`;

export const Button = styled.button`
  ${iconWrapStyle}
`;

export const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 42px;
  border: 1px solid;
  border-color: var(--color-black);
  border-radius: 21px;
  background-color: rgba(var(--color-white-rpg), 0.5);
  font: var(--font20);
  text-align: center;
  text-decoration: none;
  color: var(--color-black);
  padding: 0 20px;
  @media (hover) {
    &:hover {
      border-color: var(--color-green-active);
    }
  }
  &:active,
  &.active {
    border-color: var(--color-green-active);
    background-color: var(--color-green-active);
  }
  @media (max-width: 991px) {
    visibility: ${({ $onlyDesktop }) => ($onlyDesktop ? 'hidden' : 'visible')};
    width: ${({ $onlyDesktop }) => ($onlyDesktop ? 0 : 'auto')};
  }
`;

export const EyeIcon = styled(SmallEye)`
  width: 26px;
  height: 14px;
  color: inherit;
  & > path {
    color: inherit;
    stroke: currentColor;
  }
  & > path:first-of-type {
    fill: currentColor;
  }
`;

export const MenuIcon = styled(Menu)`
  width: 40px;
  height: 40px;
  color: inherit;
  & > path {
    color: inherit;
    stroke: currentColor;
  }
`;
