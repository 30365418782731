import React, { useRef, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useIsLoggedIn, useUserActions } from 'store/user';
import BackButton from 'components/BackButton';

import { getLinks } from './utils';
import LangSwitcher from './LangSwitcher';
import { Container, Wrapper, Items, LinkWrap, Link } from './styles';

const Menu = ({ isOpened, closeMenu }) => {
  const ref = useRef();
  const { t } = useTranslation('app');
  const isLoggedIn = useIsLoggedIn();
  const { logout } = useUserActions();
  const handleClick = useCallback(() => logout(), [logout]);
  const links = useMemo(() => getLinks(isLoggedIn), [isLoggedIn]);

  useEffect(() => {
    if (isOpened) {
      const onClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (e.target === ref.current) closeMenu();
      };

      window.document.body.addEventListener('click', onClick);

      return () => window.document.body.removeEventListener('click', onClick);
    }
    return () => null;
  }, [closeMenu, isOpened]);

  return (
    <Container ref={ref} $isOpened={isOpened}>
      <Wrapper $isOpened={isOpened}>
        <BackButton name={t('Close')} onClick={closeMenu} />
        <LangSwitcher />
        <Items $isOpened={isOpened}>
          {links.map(([to, name, onlyMobile]) => (
            <LinkWrap key={to} $onlyMobile={onlyMobile}>
              <Link to={to} onClick={to === '' ? handleClick : undefined}>
                {t(name)}
              </Link>
            </LinkWrap>
          ))}
        </Items>
      </Wrapper>
    </Container>
  );
};

Menu.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default Menu;
