import { path, pathEq } from 'ramda';

import { FEATURE_NAME as $, CURRENT, FILTERS, LICENSES, HEALTH_RECORD, STATUS } from './consts';

export const getPatient = path([$, CURRENT]);
export const getPatientProp = (name) => path([$, CURRENT, name]);
export const getPatientLicenses = path([$, LICENSES]);
export const getHealthRecord = path([$, HEALTH_RECORD]);
export const getAlarmsFiltersByType = (name) => path([$, FILTERS, name]);
export const getIsAlleyeOnePatient = pathEq(300, [$, CURRENT, STATUS]);
