import styled from 'styled-components';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

import { fontStyles } from 'theme';

export const Container = styled.header`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  row-gap: 15px;
  position: sticky;
  top: 0;
  max-width: 100%;
  background-color: var(--color-white);
  padding-bottom: 15px;
  z-index: 10;
`;

export const Name = styled.div`
  display: flex;
  align-items: center;
  ${fontStyles.font17}
  padding-left: 20px;
`;

export const Button = styled(motion.button).attrs(({ $isOpen }) => ({
  animate: { scale: $isOpen ? [1, 0, 0.999] : [0.999, 0, 1] },
  initial: false,
  transition: { duration: 0.5, times: [0, 0.5, 1] },
}))`
  justify-self: end;
  height: 50px;
  width: 50px;
  margin-right: 20px;
  @media (max-width: 374px) {
    width: 40px;
    margin-left: 8px;
  }
  @media (max-width: 360px) {
    margin-right: 15px;
  }
`;

export const Menu = styled(motion.div).attrs(({ $isOpen }) => ({
  variants: {
    close: { height: 0, transition: { duration: 0.1 } },
    open: {
      height: 'auto',
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.05,
      },
    },
  },
  animate: $isOpen ? 'open' : 'close',
  initial: 'close',
}))`
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  z-index: 10;
`;

export const MenuWrap = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Item = styled(motion(NavLink)).attrs(() => ({
  variants: {
    close: { x: '100%', opacity: 0 },
    open: { x: 0, opacity: 1 },
  },
}))`
  display: block;
  height: 50px;
  border: 2px solid var(--color-green-active);
  border-radius: 25px;
  background-color: var(--color-green-active);
  margin: 10px 15px;
  ${fontStyles.font17}
  line-height: 46px;
  text-align: center;
  text-decoration: none;
  @media (hover) {
    &:hover {
      background-color: var(--color-white);
    }
  }
  &.active {
    background-color: var(--color-white);
  }
`;
