import React, { useState, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAppError } from 'store/app';
import { useFullName, useUserActions } from 'store/user';
import AppError from '../AppError';

import Icon from './Icon';
import { Container, Name, Button, Menu, MenuWrap, Item } from './styles';

const Mobile = () => {
  const headerRef = useRef();
  const menuRef = useRef();
  const { t } = useTranslation('app');
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const userName = useFullName();
  const appError = useAppError();
  const { logout } = useUserActions();

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  const checkMenuPosition = useCallback(() => {
    const header = headerRef.current;
    const menu = menuRef.current;
    if (!(header || menu)) return;

    menu.style.top = `${Math.max(0, header.getBoundingClientRect().bottom || 0)}px`;
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  useLayoutEffect(() => {
    if (isOpen) {
      checkMenuPosition();
      window.document.addEventListener('scroll', checkMenuPosition);
      window.addEventListener('resize', checkMenuPosition);

      return () => {
        window.document.removeEventListener('scroll', checkMenuPosition);
        window.removeEventListener('resize', checkMenuPosition);
      };
    }
    return () => null;
  }, [checkMenuPosition, isOpen, appError]);

  return (
    <>
      <Container ref={headerRef}>
        <AppError />
        <Name>{userName}</Name>
        <Button type="button" onClick={handleClick} $isOpen={isOpen} aria-label="Menu">
          <Icon isOpen={isOpen} />
        </Button>
      </Container>
      <Menu ref={menuRef} $isOpen={isOpen}>
        <MenuWrap>
          <Item to="/dashboard">{t('Dashboard')}</Item>
          <Item to="/patients">{t('Patients')}</Item>
          <Item to="/account">{t('Admin')}</Item>
          <Item to="/profile">{userName}</Item>
          <Item to="" onClick={logout}>
            {t('LogOut')}
          </Item>
        </MenuWrap>
      </Menu>
    </>
  );
};

export default Mobile;
