import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { fontStyles } from 'theme';

export const Container = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: { height: $isOpen ? 'auto' : 0 },
  initial: false,
  transition: { duration: 0.5 },
}))`
  grid-column: 1/3;
  grid-row: 1/2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  width: 100%;
  background-color: var(--color-red-active);
  overflow: hidden;
`;

export const Text = styled.p`
  ${fontStyles.font14}
  color: var(--color-white);
  padding: 5px 20px;
`;

export const SupportLink = styled(Link)`
  ${fontStyles.font14}
  color: var(--color-white);
`;
