import { takeLatest, call, select, put, takeEvery } from 'redux-saga/effects';
import { omit } from 'ramda';

import api, { updateToken } from 'api';
import { withAlert, applyCancelToken } from 'store/alerts';
import { getUserProp } from 'store/user/selectors';
import { updateUser } from 'store/user/actions';
import { setLanguage } from '../utils';

import { SUB_ORGANIZATION, LIMIT, TOKEN, LANGUAGE, ID, SORT_BY, SORT_DIR, META, CREATED, DESC, EXPAND, SUB_PRODUCT } from '.';
import {
  FETCH_ORGANIZATION,
  CREATE_ORGANIZATION,
  ACTIVATE_ORGANIZATION,
  SAVE_ORGANIZATION,
  FETCH_ORGANIZATION_DASHBOARD,
  FETCH_TEAM,
  CREATE_CARD_SECRET,
  FETCH_PAYMENT_HISTORY,
  FETCH_PRODUCTS,
  CREATE_PURCHASE,
} from './types';
import { updateOrganization, updateTeam } from './actions';

function* fetchOrganization(action) {
  const id = yield select(getUserProp(SUB_ORGANIZATION));
  const organization = yield call(api.get, `/organizations/${id}`, applyCancelToken(action));
  const team = yield call(api.get, `/organizations/${id}/team`, { params: { [LIMIT]: 50 }, ...applyCancelToken(action) });

  yield put(updateOrganization(organization));
  yield put(updateTeam(team.data));
}

function* createOrganization({ payload }) {
  return yield call(api.post, '/organizations', payload);
}

function* activateOrganization({ payload }) {
  const data = yield call(api.post, '/organizations/activate', payload);
  updateToken(data[TOKEN]);
  setLanguage(data[LANGUAGE]);
  yield put(updateUser(data));

  return { success: data };
}

function* saveOrganization({ payload, ...rest }) {
  const id = yield select(getUserProp(SUB_ORGANIZATION));
  const data = yield call(api.patch, `/organizations/${id}`, payload, applyCancelToken(rest));

  yield put(updateOrganization(data));

  return { success: 'Organization Update Succeeded' };
}

function* fetchOrganizationDashboard(action) {
  const id = yield select(getUserProp(SUB_ORGANIZATION));
  return { success: yield call(api.get, `/organizations/${id}/dashboard`, applyCancelToken(action)) };
}

function* fetchTeam(action) {
  const id = yield select(getUserProp(SUB_ORGANIZATION));
  const data = yield call(api.get, `/organizations/${id}/team`, { params: { [LIMIT]: 50 }, ...applyCancelToken(action) });
  yield put(updateTeam(data.data));
}

function* createCardSecret(action) {
  const id = yield select(getUserProp(SUB_ORGANIZATION));

  return { success: yield call(api.post, `/organizations/${id}/cardsecret`, {}, applyCancelToken(action)) };
}

function* fetchPaymentHistory(action) {
  const data = yield call(api.get, '/payments', {
    params: { [LIMIT]: 50, [SORT_BY]: `${META}.${CREATED}`, [SORT_DIR]: DESC, [EXPAND]: SUB_PRODUCT },
    ...applyCancelToken(action),
  });

  return { success: data.data };
}

function* fetchProducts(action) {
  const { data } = yield call(api.get, '/products', { params: { [LIMIT]: 50 }, ...applyCancelToken(action) });

  return { success: data };
}

function* createPurchase({ payload }) {
  yield call(api.post, `/products/${payload[ID]}/purchase`, omit([ID], payload));
}

export default function* watchOrganization() {
  yield takeLatest(FETCH_ORGANIZATION, withAlert(fetchOrganization));
  yield takeEvery(CREATE_ORGANIZATION, withAlert(createOrganization));
  yield takeEvery(ACTIVATE_ORGANIZATION, withAlert(activateOrganization));
  yield takeLatest(SAVE_ORGANIZATION, withAlert(saveOrganization));
  yield takeLatest(CREATE_CARD_SECRET, withAlert(createCardSecret));
  yield takeLatest(FETCH_TEAM, withAlert(fetchTeam));
  yield takeLatest(FETCH_ORGANIZATION_DASHBOARD, withAlert(fetchOrganizationDashboard));
  yield takeLatest(FETCH_PAYMENT_HISTORY, withAlert(fetchPaymentHistory));
  yield takeLatest(FETCH_PRODUCTS, withAlert(fetchProducts));
  yield takeEvery(CREATE_PURCHASE, withAlert(createPurchase));
}
