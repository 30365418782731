import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { prop, pipe, map, cond, not, is, anyPass, T } from 'ramda';

import i18n from 'locale';
import { APP_LANGS } from 'locale/consts';

export const createName = (feature) => (name) => `${feature}/${name}`;

export const setLanguage = (lng) => {
  if (i18n.language !== lng && APP_LANGS.includes(lng)) {
    i18n.changeLanguage(lng);
  }
};

export const wrapActions = (actions) => () => {
  const dispatch = useDispatch();

  return useMemo(() => map((action) => pipe(action, dispatch), actions), [dispatch]);
};

export const wrapSelector = (selector) => (shallowEqual) => useSelector(selector, shallowEqual);
export const wrapSelectorWithArg = (selector) => (arg, shallowEqual) => useSelector(selector(arg), shallowEqual);
export const wrapMultiSelector = (selectorByItem, selectorForAllItem) => (arg, shallowEqual) =>
  cond([
    [pipe(Boolean, not), () => useSelector(selectorForAllItem, shallowEqual)],
    [is(String), ($) => useSelector(selectorByItem($), shallowEqual)],
    [anyPass([is(Array), is(Object)]), map(($) => useSelector(selectorByItem($), shallowEqual))],
    [T, () => useSelector(selectorForAllItem, shallowEqual)],
  ])(arg);

export const normalizeArr = (field, arr) =>
  arr.reduce((acc, item) => {
    acc[prop(field, item)] = item;

    return acc;
  }, {});
