import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

import { ReactComponent as DefIcon } from 'assets/svg-icons/linkArrow.svg';

const linkStyle = ({ $withoutIcon }) => `
  display: flex;
  align-items: center;
  width: fit-content;
  height: 40px;
  border: 1px solid;
  border-color: var(--color-black);
  border-radius: 20px;
  background-color: rgba(var(--color-white-rpg), 0.5);
  padding: 0 20px 0 50px;
  position: relative;
  text-decoration: none;
  @media (hover) {
    &:hover {
      background-color: var(--color-green-hover);
      border-color: var(--color-green-hover);
    }
  }
  &:active {
    background-color: var(--color-green-active);
    border-color: var(--color-green-active);
  }
  @media (max-width: 991px) {
    height: 36px;
    border-radius: 18px;
    padding: ${$withoutIcon ? '0 10px' : '0 10px 0 40px'};
  }
`;

export const NavLink = styled(Link)`
  ${linkStyle}
  &.active {
    background-color: var(--color-green-active);
    border-color: var(--color-green-active);
  }
`;

export const A = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  ${linkStyle}
`;

export const Icon = styled(DefIcon)`
  width: 40px;
  height: 40px;
  border: 1px solid var(--color-black);
  border-radius: 50%;
  position: absolute;
  inset: -1px auto -1px -1px;
  @media (max-width: 991px) {
    width: 36px;
    height: 36px;
    ${({ $withoutIcon }) => $withoutIcon && 'display: none;'}
  }
`;

export const Text = styled.p`
  font: var(--font30);
  color: var(--color-black);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: 991px) {
    font: var(--font20);
  }
`;
