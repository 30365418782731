import alleyeInUsePreImg from 'assets/img/alleyeInUse/preload.jpg';
import alleyeInUse906Webp from 'assets/img/alleyeInUse/w906.webp';
import alleyeInUse906Jpg from 'assets/img/alleyeInUse/w906.jpg';
import alleyeInUse1812Webp from 'assets/img/alleyeInUse/w1812.webp';
import alleyeInUse1812Jpg from 'assets/img/alleyeInUse/w1812.jpg';
import alleyeInUse2718Webp from 'assets/img/alleyeInUse/w2718.webp';
import alleyeInUse2718Jpg from 'assets/img/alleyeInUse/w2718.jpg';

import slitInUsePreImg from 'assets/img/slitInUse/preload.jpg';
import slitInUse907Webp from 'assets/img/slitInUse/w907.webp';
import slitInUse907Jpg from 'assets/img/slitInUse/w907.jpg';
import slitInUse1814Webp from 'assets/img/slitInUse/w1814.webp';
import slitInUse1814Jpg from 'assets/img/slitInUse/w1814.jpg';
import slitInUse2721Webp from 'assets/img/slitInUse/w2721.webp';
import slitInUse2721Jpg from 'assets/img/slitInUse/w2721.jpg';

const sizes = [
  '(max-width: 1510px) and (max-height: 906px) 543px',
  '(max-width: 3020px) and (max-height: 1812px) 1087px',
  '60vw',
].join(',');

export const IMAGE_DATA = {
  app: {
    webpSrc: [`${alleyeInUse906Webp} 906w`, `${alleyeInUse1812Webp} 1812w`, `${alleyeInUse2718Webp} 2718w`].join(','),
    jpgSrc: [`${alleyeInUse906Jpg} 906w`, `${alleyeInUse1812Jpg} 1812w`, `${alleyeInUse2718Jpg} 2718w`].join(','),
    sizes,
    preloadImg: alleyeInUsePreImg,
    defaultImage: alleyeInUse906Jpg,
    alt: 'alleye_in_use',
  },
  slit: {
    webpSrc: [`${slitInUse907Webp} 907w`, `${slitInUse1814Webp} 1814w`, `${slitInUse2721Webp} 2721w`].join(','),
    jpgSrc: [`${slitInUse907Jpg} 907w`, `${slitInUse1814Jpg} 1814w`, `${slitInUse2721Jpg} 2721w`].join(','),
    sizes,
    preloadImg: slitInUsePreImg,
    defaultImage: slitInUse907Jpg,
    alt: 'slit_lamp_in_use',
  },
};
