import { useRef, useState, useEffect, useCallback } from 'react';

export const useAsyncState = (init) => {
  const isMount = useRef();
  const [state, setState] = useState(init);

  const handleChange = useCallback((value) => {
    if (!isMount.current) return;

    setState(value);
  }, []);

  useEffect(() => {
    isMount.current = true;

    return () => {
      isMount.current = false;
    };
  }, []);

  return [state, handleChange, isMount];
};
