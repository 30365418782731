import React from 'react';
import PropTypes from 'prop-types';

import { Container, Loader } from './styles';

const Spinner = ({ className }) => (
  <Container className={className}>
    <Loader />
  </Container>
);

Spinner.propTypes = { className: PropTypes.string };

export default Spinner;
