import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_USER,
  LOGOUT,
  FETCH_LICENSE_BY_VOUCHER,
  FETCH_STATISTICS,
  RESTORE,
  LOGIN,
  REGISTER_USER,
  REGISTER_ABROAD_USER,
  SAVE_USER,
  DELETE_USER,
  UPDATE_PASSWORD,
  RESET_PASSWORD,
  SAVE_PASSWORD,
  CREATE_2FA,
  VALIDATE_2FA,
  DELETE_2FA,
  AUTH_2FA,
} from './types';

export const updateUser = createAction(UPDATE_USER);
export const logout = createAction(LOGOUT);

export const fetchLicenseByVoucher = createAlertAction(FETCH_LICENSE_BY_VOUCHER);
export const fetchStatistics = createAlertAction(FETCH_STATISTICS);
export const restore = createAlertAction(RESTORE);
export const login = createAlertAction(LOGIN);
export const registerUser = createAlertAction(REGISTER_USER);
export const registerAbroadUser = createAlertAction(REGISTER_ABROAD_USER);
export const saveUser = createAlertAction(SAVE_USER);
export const deleteUser = createAlertAction(DELETE_USER);
export const updatePassword = createAlertAction(UPDATE_PASSWORD);
export const resetPassword = createAlertAction(RESET_PASSWORD);
export const savePassword = createAlertAction(SAVE_PASSWORD);
export const create2fa = createAlertAction(CREATE_2FA);
export const validate2fa = createAlertAction(VALIDATE_2FA);
export const delete2fa = createAlertAction(DELETE_2FA);
export const auth2fa = createAlertAction(AUTH_2FA);
