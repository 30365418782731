import { handleActions } from 'redux-actions';
import { equals, ifElse, props, is } from 'ramda';

import { SEARCH, SORT_BY, SORT_DIR, START_AFTER, LIMIT, COUNT, HAS_MORE } from '.';
import { CURRENT, FILTERS, FILTER, LICENSES, HEALTH_RECORD, ALLEYE, LICENSE, UNCONNECTED } from './consts';
import { UPDATE_PATIENT, RESET_PATIENT, UPDATE_ALARMS_FILTERS_BY_TYPE, UPDATE_LICENSES, UPDATE_HEALTH_RECORD } from './types';

const initFilters = {
  [FILTER]: '',
  [SEARCH]: '',
  [SORT_BY]: '',
  [SORT_DIR]: '',
  [START_AFTER]: 0,
  [LIMIT]: 10,
  [COUNT]: 0,
  [HAS_MORE]: false,
};
const init = {
  [CURRENT]: {},
  [FILTERS]: {
    [ALLEYE]: initFilters,
    [LICENSE]: initFilters,
    [UNCONNECTED]: initFilters,
    default: initFilters,
  },
};

const propsForReset = ['type', SEARCH, SORT_BY, SORT_DIR];
const getStartAfter = ifElse(
  (old, updated) => equals(props(propsForReset, old), props(propsForReset, updated)),
  (old, updated) => (is(Number, updated[START_AFTER]) ? updated[START_AFTER] : old[START_AFTER]),
  () => 0
);

export default handleActions(
  {
    [UPDATE_PATIENT]: (state, { payload }) => ({
      ...state,
      [CURRENT]: { ...state[CURRENT], ...payload },
    }),
    [RESET_PATIENT]: (state) => ({
      ...state,
      [CURRENT]: init[CURRENT],
      [LICENSES]: init[LICENSES],
      [HEALTH_RECORD]: init[HEALTH_RECORD],
    }),
    [UPDATE_ALARMS_FILTERS_BY_TYPE]: (state, { payload }) => {
      const type = payload.type || 'default';

      return {
        ...state,
        [FILTERS]: {
          ...state[FILTERS],
          [type]: {
            ...state[FILTERS][type],
            ...payload,
            type,
            [START_AFTER]: getStartAfter(state[FILTERS][type], { ...state[FILTERS][type], ...payload, type }),
          },
        },
      };
    },
    [UPDATE_LICENSES]: (state, { payload }) => ({
      ...state,
      [LICENSES]: payload,
    }),
    [UPDATE_HEALTH_RECORD]: (state, { payload }) => ({
      ...state,
      [HEALTH_RECORD]: payload,
    }),
  },
  init
);
