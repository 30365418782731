import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import { pipe, replace, slice, prop, __, when, has } from 'ramda';

import * as APP_CONSTS from 'api/consts';

import { DEFAULT_LANGUAGE, EN, DE, IT, ZH, AR, RTL_LANGS } from './consts';

const VARIABLES = {
  ...process.env,
  ...APP_CONSTS,
};

const filesForReplace = ['termsAndConditions', 'privacy', 'safety', 'accessibility'];

const jsonReplacer = pipe(
  JSON.stringify,
  replace(/%\S+%/g, pipe(slice(1, -1), when(has(__, VARIABLES), prop(__, VARIABLES)))),
  JSON.parse
);

export const requireFn =
  (lang, prefix = '') =>
  (acc, name) => {
    const file = require(`.${prefix}/${lang}/${name}.json`); // eslint-disable-line import/no-dynamic-require

    acc[name] = filesForReplace.includes(name) ? jsonReplacer(file) : file;

    return acc;
  };

const resourceItems = [
  'abroadRegistration',
  'actions',
  'app',
  'bugreport',
  'forms',
  'home',
  'login',
  'messages',
  'organization',
  'overview',
  'patients',
  'profile',
  'register',
  'appointment',
  'provider',
  'news',
  'termsAndConditions',
  'accessibility',
  'alleye',
  'customerprivacy',
  'privacy',
  'oculocare',
  'safety',
  'serviceReport',
  'faq',
  'research',
];
const abroadResourceItems = ['abroadRegistration', 'forms', 'messages'];

const resources = {
  [DE]: resourceItems.reduce(requireFn(DE), {}),
  [EN]: resourceItems.reduce(requireFn(EN), {}),
  [IT]: resourceItems.reduce(requireFn(IT), {}),
  [ZH]: abroadResourceItems.reduce(requireFn(ZH), {}),
  [AR]: abroadResourceItems.reduce(requireFn(AR), {}),
};

const checkRtl = () => {
  i18n.isRtl = RTL_LANGS.includes(i18n.language);
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init(
    {
      resources,
      fallbackLng: DEFAULT_LANGUAGE,
      keySeparator: '$.',
      debug: process.env.NODE_ENV === 'development',
      nsSeparator: ':',
      interpolation: {
        escapeValue: false,
      },
    },
    checkRtl
  );

i18n.on('languageChanged', checkRtl);

export default i18n;
