import { LATEST_NEWS_DATE } from './consts';

export const getLatestNewsDate = () => {
  const dateFromStorage = window.localStorage.getItem(LATEST_NEWS_DATE);

  if (dateFromStorage) return dateFromStorage;

  const date = new Date(2020, 0, 1).toISOString();

  window.localStorage.setItem(LATEST_NEWS_DATE, date);

  return date;
};

export const updateLatestNewsDate = () => {
  const date = new Date().toISOString();

  window.localStorage.setItem(LATEST_NEWS_DATE, date);

  return date;
};

export const checkBrowserUS = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  if (!timeZone) return false;

  return timeZone.includes('US/') || timeZone.includes('America/');
};
