import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { NavLink, A, Icon, Text } from './styles';

const ArrowLink = forwardRef(({ to, href, withoutIcon = false, onClick, className, children = null }, ref) => {
  const Link = to ? NavLink : A;

  return (
    <Link ref={ref} to={to} href={href} $withoutIcon={withoutIcon} onClick={onClick} className={className} aria-label={children}>
      <Icon $withoutIcon={withoutIcon} />
      <Text>{children}</Text>
    </Link>
  );
});

ArrowLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  withoutIcon: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.string,
};

export default ArrowLink;
