import styled from 'styled-components';

import { ReactComponent as TriangleIcon } from 'assets/svg-icons/triangle.svg';

export const Button = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 25px;
  position: relative;
  border: 1px solid;
  border-color: var(--color-grey-text);
  border-radius: 13px;
  background-color: rgba(var(--color-white-rpg), 0.5);
  @media (hover) {
    &:hover {
      border-color: var(--color-green-active);
    }
  }
  &:active {
    border-color: var(--color-green-active);
    background-color: var(--color-green-active);
  }
`;

export const Text = styled.p`
  font: var(--font20);
  color: var(--color-black);
  text-align: center;
  padding: 0 38px 0 15px;
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: -1px -1px -1px auto;
  width: 25px;
  border: 1px solid;
  border-color: var(--color-black);
  border-radius: 13px;
  background-color: var(--color-black);
  color: var(--color-white);
  transform: rotate(90deg);
  @media (hover) {
    ${Button}:hover>& {
      border-color: var(--color-green-active);
      background-color: var(--color-green-active);
      color: var(--color-black);
    }
  }
  ${Button}:active>& {
    border-color: var(--color-black);
    background-color: var(--color-green-active);
    color: var(--color-black);
  }
`;

export const Icon = styled(TriangleIcon)`
  width: 25px;
  height: 25px;
  color: inherit;
  & > path {
    color: inherit;
    fill: currentColor;
  }
`;
