export const FEATURE_NAME = 'app';

export const APP_ERROR = 'appError';
export const LATEST_NEWS_DATE = 'latestNewsDate';
export const HAVE_SEEN_LANDING_ANIMATION = 'haveSeenLadingAnimation';
export const BROWSER_COUNTRY = 'browserCountry';

export const EMAIL = 'email';
export const MESSAGE = 'message';
export const COPY = 'copy';
export const SCREENSHOT = 'screenshot';
export const COUNTRY = 'country';
export const LANGUAGE = 'language';
export const PHONE = 'phone';
export const TOPO = 'topo';
export const COUNTRIES = 'countries';
export const ALPHA_2_TOPO = 'Alpha-2';
export const ALPHA_2_COUNTRY = 'alpha2';
export const INSTITUTION = 'institution';

export const ACTION = 'action';
