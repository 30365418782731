import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../InternalHeader';

import { Main, Footer, MarginBox } from './styles';

const InternalLayout = () => (
  <>
    <Header />
    <Main>
      <Outlet />
    </Main>
    <Footer />
    <MarginBox />
  </>
);

export default InternalLayout;
