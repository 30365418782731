import { prop, path, pipe, paths, filter, join, when, includes, allPass, anyPass, not } from 'ramda';

import { TOKEN, TOKEN_2FA } from 'api';
import { ID } from 'api/consts';

import { FEATURE_NAME as $, TWO_FACTOR_ENABLED, ROLES, ADMIN_ROLE, FIRST_NAME, LAST_NAME } from './consts';

export const getUser = prop($);
export const getUserProp = (pr) => path([$, pr]);
export const getIsLoggedIn = anyPass([
  allPass([() => localStorage[TOKEN_2FA], getUserProp(TWO_FACTOR_ENABLED), getUserProp(ID)]),
  allPass([() => localStorage[TOKEN], pipe(getUserProp(TWO_FACTOR_ENABLED), not), getUserProp(ID)]),
]);
export const getIsAdmin = pipe(getUserProp(ROLES), when(Boolean, includes(ADMIN_ROLE)));
export const getFullName = pipe(
  paths([
    [$, FIRST_NAME],
    [$, LAST_NAME],
  ]),
  filter(Boolean),
  join(' ')
);
