import styled from 'styled-components';

import DefSpinner from 'components/Spinner';

export const Spinner = styled(DefSpinner)`
  grid-column: 1/-1;
  flex: 1 1 auto;
  width: 100%;
  min-height: calc(75 * var(--vh, 1vh));
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;
