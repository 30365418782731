document.querySelector('html').innerHTML = `
<head>
  <meta charset="utf-8" />
  <meta http-equiv="x-ua-compatible" content="ie=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <title>Alleye | Browser</title>
  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
  <link rel="manifest" href="/site.webmanifest" />
  <style>
    * {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 20px;
      font-weight: normal;
      line-height: 1.5;
      color: #000;
    }

    html,
    body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .IEWarn-container {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: 630px;
      margin: auto;
      padding: 40px 0 60px;
    }

    svg {
      display: flex;
      margin: 0 auto 40px;
    }

    tspan {
      font-size: 70px;
    }

    h1 {
      font-size: 33px;
    }

    p {
      font-size: 20px;
      margin: 1em 0;
    }

    ul {
      list-style-type: none;
      margin: 0;
    }

    li {
      font-size: 20px;
      font-weight: bold;
    }

    li:before {
      content: '${String.fromCharCode(8594)}';
      padding-right: 0.3em;
    }

    a {
      color: #0060fb;
      text-decoration: none;
    }

    .IEWarn-offset {
      padding-left: 1.3em;
    }
  </style>
</head>

<body>
  <div class="IEWarn-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="122" height="122" viewBox="0 0 122 122">
      <g fill="none" fillRule="evenodd">
        <text fill="#000">
          <tspan x="51.5" y="85">
            !
          </tspan>
        </text>
        <circle cx="61" cy="61" r="60" stroke="#000" strokeWidth="2" />
      </g>
    </svg>
    <h1>Your browser is no longer supported</h1>
    <p>
      Please use a modern browser for better security and user experience. We officially support the latest versions of
      the following browsers on alleye.io:
    </p>
    <ul>
      <li>
        Chrome (
        <a href="https://www.google.com/chrome" target="_blank" rel="noopener noreferrer">
          https://www.google.com/chrome
        </a>
        )
      </li>
      <li>
        Edge (
        <a href="https://microsoft.com/edge" target="_blank" rel="noopener noreferrer">
          https://microsoft.com/edge
        </a>
        )
      </li>
      <li>
        Firefox (
        <a href="https://www.mozilla.org/firefox" target="_blank" rel="noopener noreferrer">
          https://www.mozilla.org/firefox
        </a>
        )
      </li>
      <li>
        Safari (
        <a href="https://www.apple.com/safari" target="_blank" rel="noopener noreferrer">
          https://www.apple.com/safari
        </a>
        )
      </li>
      <li>
        Any browser based on Webkit / Chromium engines
        <br />
        <span class="IEWarn-offset">(Opera, Samsung Internet, UCBrowser, etc.)</span>
      </li>
    </ul>
    <p>Please note that compatible browsers are included with most operating systems:</p>
    <ul>
      <li>
        Windows: <span>Edge</span>
      </li>
      <li>
        Android: <span>Chrome</span>
      </li>
      <li>
        MacOS / iOS: <span>Safari</span>
      </li>
    </ul>
  </div>
</body>
`;
