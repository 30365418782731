import React from 'react';
import PropTypes from 'prop-types';

import { Link, A, IconWrap, Icon, Text } from './styles';

const ArrowLink = ({ to, href, onClick, children = null }) => {
  const Container = to ? Link : A;

  return (
    <Container to={to} href={href} onClick={onClick} aria-label={children}>
      <IconWrap>
        <Icon />
      </IconWrap>
      <Text>{children}</Text>
    </Container>
  );
};

ArrowLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.string,
};

export default ArrowLink;
