import styled from 'styled-components';
import { motion } from 'framer-motion';

import { ReactComponent as TriangleIcon } from 'assets/svg-icons/triangle.svg';

export const Container = styled.div`
  max-width: 100%;
  overflow: hidden;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 25px;
  max-width: 100%;
  border-radius: 13px;
  background-color: var(--color-white);
  padding-right: 10px;
`;

export const Title = styled.h2`
  font: var(--font20);
  color: var(--color-black);
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Icon = styled(motion(TriangleIcon)).attrs(({ $isOpened }) => ({
  animate: { rotate: $isOpened ? 0 : -90 },
  transition: { duration: 0.3 },
  initial: { rotate: -90 },
}))`
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  border: 1px solid var(--color-black);
  border-radius: 13px;
`;

export const Content = styled(motion.div).attrs(({ $isOpened }) => ({
  animate: { height: $isOpened ? 'auto' : 0 },
  transition: { duration: 0.3 },
  initial: false,
}))`
  display: flex;
  flex-direction: column;
  height: 0;
  padding-left: 33px;
  overflow: hidden;
`;
